import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { EventManagerService } from '@global/event-manager.service';
import { prepareQueryParams } from '@helpers/prepare-query-params';

import { CommentairePublication } from '@app/publication/commentaire-publication.model';
import { DocumentService } from '@app/document/document.service';
import { LienPublication } from '@app/publication/lien-publication.model';
import { Media } from '@app/medias/media.model';
import { PieceJointe } from '@app/publication/piece-jointe.model';
import { PortailService, PortailParam } from '@app/portail/portail.service';
import { Publication } from '@app/publication/publication.model';

import { clone, convertDateFieldsToDate, convertDateFieldsToString, trimHTML } from '@helpers/utils';

@Injectable({ providedIn: 'root' })
export class PublicationService {

	private emoticonesSubject = new BehaviorSubject<any[]>([]);

	constructor(
		private http: HttpClient,
		private documentService: DocumentService,
		private eventManager: EventManagerService,
		private portailService: PortailService,
		private sanitizer: DomSanitizer
	) {

	}

	public get emoticones() {
		return this.emoticonesSubject.getValue();
	}

	public getEmoticones() {
		if (this.emoticones && this.emoticones.length) {
			return new Observable<any>(subscriber=> {
				subscriber.next(this.emoticones);
				subscriber.complete();
			});
		}
		else {
			return this.http.get<any>(`${environment.api_url}/emoticones`)
			.pipe(map(response => {
				this.emoticonesSubject.next(response['emoticones']);
				return this.emoticones;
			}));
		}
	}

	public getDateModification(publication: Publication) {
		let dateModification: Date = publication.pub_date_debut_diffusion || publication.pub_date_creation;
		if (publication.media) {
			if (!Array.isArray(publication.media)) {
				if (publication.media.med_date_ajout > dateModification) dateModification = publication.media.med_date_ajout;
			}
			else {
				publication.media.forEach((media: Media) => {
					if (media.med_date_ajout > dateModification) dateModification = media.med_date_ajout;
				});
			}
		}
		if (publication.pieces_jointes_publications) {
			publication.pieces_jointes_publications.forEach((pj: PieceJointe) => {
				if (pj.media && pj.media.med_date_ajout > dateModification) dateModification = pj.media.med_date_ajout;
			});
		}
		return dateModification;
	}

	public preparePublicationFromServer(publication: Publication) {
		let tmp = clone(publication);
		convertDateFieldsToDate(tmp, true);
		tmp.pub_date_modification = this.getDateModification(tmp);
		tmp.pieces_jointes_publications.forEach((one: PieceJointe) => {
			if (one.media) {
				one.media.media_type = this.documentService.getFileType(one.media.med_fichier);
				// one.media.url = this.sanitizer.bypassSecurityTrustResourceUrl('https://' + location.hostname + one.media.url);
				if (one.media.media_type == 'pdf') {
					one.media.url = this.sanitizer.bypassSecurityTrustResourceUrl(one.media.url.toString());
				}

			}
		})
		return tmp;
	}

	public preparePublicationsFromServer(publications: Publication[]) {
		for (let i = 0; i < publications.length ; i++) {
			publications[i] = this.preparePublicationFromServer(publications[i]);
		}
	}

	public preparePublication(publication: Publication) {
		let tmp = clone(publication);
		convertDateFieldsToString(tmp, false, true);
		delete tmp.media;
		delete tmp.decompte_emoticones;
		if (tmp.pieces_jointes) {
			// tmp.pieces_jointes.forEach((one: PieceJointe) => {
			// 	delete one.media;
			// })
		}
		// tmp.pub_html = trimHTML(tmp.pub_html);
		return tmp;
	}

	public prepareCommentaireFromServer(commentaire: CommentairePublication) {
		let tmp = clone(commentaire);
		convertDateFieldsToDate(tmp, true);
		return tmp;
	}

	public prepareCommentairesFromServer(commentaires: CommentairePublication[]) {
		for (let i = 0; i < commentaires.length ; i++) {
			commentaires[i] = this.prepareCommentaireFromServer(commentaires[i]);
		}
	}

	public getDummyPublication(tpu_code: string) {
		return new Publication(tpu_code);
	}

	public getAdminState(tpu_code: string) {
		let publicationType: string;
		switch (tpu_code) {
			case 'T':
				publicationType = 'tutoriels';
				break;
			// case 'A':
			default:
				publicationType = 'actualites';
		}
		return `eqip_publication.admin-${publicationType}.single`;
	}

	public get(pub_id: number) {
		return this.http.get<any>(`${environment.api_url}/publications/${pub_id}`)
		.pipe(map(response => {
			return this.preparePublicationFromServer(response);
		}));
	}

	public getList(params: any) {
		return this.http.get<any>(`${environment.api_url}/publications`, prepareQueryParams(params))
		.pipe(map(
			(response: any) => {
				if (response.publications) {
					this.preparePublicationsFromServer(response.publications);
				}
				return response;
			}
		));
	}

	public post(publication: Publication) {
		let prepared = this.preparePublication(publication);
		return this.http.post<any>(`${environment.api_url}/publications`, prepared);
	}

	public put(publication: Publication) {
		let prepared = this.preparePublication(publication);
		return this.http.put<any>(`${environment.api_url}/publications/${publication.pub_id}`, prepared);
	}

	public delete(pub_id: number) {
		return this.http.delete<any>(`${environment.api_url}/publications/${pub_id}`);
	}

	public getListOfTypeForPortail(params: any, tpu_code: string = 'A', portail?: PortailParam) {
		switch (tpu_code) {
			case 'O':
				return this.getListOutils(params, portail);
				break;
			case 'T':
				return this.getListTutoriels(params, portail);
				break;

			default:
				return this.getListActualites(params, portail);
		}
	}

	public getOfTypeForPortail(pub_id: number, tpu_code: string = 'A', portail?: PortailParam) {
		switch (tpu_code) {
			case 'O':
				return this.getOutil(pub_id, portail);
				break;
			case 'T':
				return this.getTutoriel(pub_id, portail);
				break;
			// case 'A':
			default:
				return this.getActualite(pub_id, portail);
		}
	}

	public getListActualites(params: any, portail?: PortailParam) {
		const por_id = this.portailService.getPorIdOrCurrentPorId(portail);
		return this.http.get<any>(`${environment.api_url}/portails/${por_id}/actualites`, prepareQueryParams(params))
		.pipe(map(
			(response: any) => {
				if (response.publications) {
					this.preparePublicationsFromServer(response.publications);
				}
				return response;
			}
		));
	}

	public getActualitesPopup(portail?: PortailParam) {
		const por_id = this.portailService.getPorIdOrCurrentPorId(portail);
		return this.http.get<any>(`${environment.api_url}/portails/${por_id}/actualites_popup`);
	}

	public getActualite(pub_id: number, portail?: PortailParam) {
		const por_id = this.portailService.getPorIdOrCurrentPorId(portail);
		return this.http.get<any>(`${environment.api_url}/portails/${por_id}/actualites/${pub_id}`)
		.pipe(map(response => {
			return this.preparePublicationFromServer(response);
		}));
	}

	public setActualiteEmoji(pub_id: number, emo_id: number|null) {
		let prepared = {
			emo_id: emo_id
		}
		const por_id = this.portailService.currentPortail.por_id;
		return this.http.put<any>(`${environment.api_url}/portails/${por_id}/actualites/${pub_id}/emoticone`, prepared);
	}

	public setPublicationLecture(pub_id: number, lue: boolean) {
		let prepared = {
			lue: lue
		}
		const por_id = this.portailService.currentPortail.por_id;
		return this.http.put<any>(`${environment.api_url}/portails/${por_id}/publications/${pub_id}/lecture`, prepared);
	}

	public setActualiteMasquage(pub_id: number, masquage: boolean) {
		let prepared = {
			masquage: masquage
		}
		const por_id = this.portailService.currentPortail.por_id;
		return this.http.put<any>(`${environment.api_url}/portails/${por_id}/actualites/${pub_id}/masquage`, prepared);
	}

	public getListOutils(params: any, portail?: PortailParam) {
		const por_id = this.portailService.getPorIdOrCurrentPorId(portail);
		return this.http.get<any>(`${environment.api_url}/portails/${por_id}/outils`, prepareQueryParams(params))
		.pipe(map(
			(response: any) => {
				if (response.publications) {
					this.preparePublicationsFromServer(response.publications);
				}
				return response;
			}
		));
	}

	public getOutil(pub_id: number, portail?: PortailParam) {
		const por_id = this.portailService.getPorIdOrCurrentPorId(portail);
		return this.http.get<any>(`${environment.api_url}/portails/${por_id}/outils/${pub_id}`)
		.pipe(map(response => {
			return this.preparePublicationFromServer(response);
		}));
	}

	public getListTutoriels(params: any, portail?: PortailParam) {
		const por_id = this.portailService.getPorIdOrCurrentPorId(portail);
		return this.http.get<any>(`${environment.api_url}/portails/${por_id}/tutoriels`, prepareQueryParams(params))
		.pipe(map(
			(response: any) => {
				if (response.publications) {
					this.preparePublicationsFromServer(response.publications);
				}
				return response;
			}
		));
	}

	public getTutoriel(pub_id: number, portail?: PortailParam) {
		const por_id = this.portailService.getPorIdOrCurrentPorId(portail);
		return this.http.get<any>(`${environment.api_url}/portails/${por_id}/tutoriels/${pub_id}`)
		.pipe(map(response => {
			return this.preparePublicationFromServer(response);
		}));
	}

	public addCommentaire(pub_id: number, commentaire: string) {
		let prepared = {
			cop_commentaire: commentaire
		}
		const por_id = this.portailService.currentPortail.por_id;
		return this.http.post<any>(`${environment.api_url}/portails/${por_id}/publications/${pub_id}/commentaires`, prepared);
	}

	public getCommentairesOfPublication(params: any, pub_id: number) {
		let tmpParams = prepareQueryParams(params)
		return this.http.get<any>(`${environment.api_url}/publications/${pub_id}/commentaires`, tmpParams)
		.pipe(map(
			(response: any) => {
				if (response.commentaires_publications) {
					this.prepareCommentairesFromServer(response.commentaires_publications);
				}
				return response;
			}
		));
	}

	public getCommentairesOfPublicationForPortail(pub_id: number, portail?: PortailParam) {
		const por_id = this.portailService.getPorIdOrCurrentPorId(portail);
		return this.http.get<any>(`${environment.api_url}/portails/${por_id}/publications/${pub_id}/commentaires`)
		.pipe(map(
			(response: any) => {
				if (response.commentaires_publications) {
					this.prepareCommentairesFromServer(response.commentaires_publications);
				}
				return response;
			}
		));
	}


}
