<form #utilisateurForm="ngForm">

	<p-panel>
		<ng-template pTemplate="header">
			<div class="flex align-items-center">
				<div class="p-panel-title mr-3">{{title}}</div>
				<div class="field-checkbox mb-0" *ngIf="!selfEditing">
					<p-checkbox
						name="uti_actif"
						inputId="uti_actif"
						[(ngModel)]="utilisateur.uti_actif"
						[binary]="true"
						inputId="binary"
						[label]="utilisateur.uti_actif? 'Actif' : 'Inactif'"
						[disabled]="!editMode || formDisabled"
					></p-checkbox>
				</div>
			</div>
		</ng-template>

		<ng-template pTemplate="icons" *ngIf="canEdit">
			<p-button class="label-md-none" label="Editer" icon="pi pi-pencil" (click)="toggleEditMode()" *ngIf="!editMode">
			</p-button>
			<button
				type="button"
				pButton
				class="label-md-none"
				[label]="utilisateur.uti_id? 'Enregistrer' : 'Ajouter'"
				icon="pi pi-save"
				(click)="submit()"
				[loading]="loading"
				[disabled]="!utilisateurForm.valid"
				*ngIf="!!editMode"
			>
			</button>
		</ng-template>

		<fieldset [disabled]="formDisabled">

			<div class="p-fluid formgrid grid">
				<div class="field col-12 md:col-6 xl:col-4">
					<label for="uti_email">Email</label>
					<input
						type="email"
						pInputText
						name="uti_email"
						#uti_email="ngModel"
						[(ngModel)]="utilisateur.uti_email"
						required
						email="true"
						[ngClass]="{ 'p-invalid': submitted && uti_email.errors }"
						placeholder="email@exemple.com"
						(blur)="!uti_email.errors && checkIfUtilisateurExists()"
						[readonly]="!editMode"
						*ngIf="!showUtilisateurSelector"
					>
					<utilisateur-selector
						name="uti_email"
						#utilisateurSelector
						[editable]="true"
						[ctx_id]="ctx_id"
						[emailAsValue]="true"
						[(ngModel)]="utilisateur.uti_email"
						placeholder="E-mail"
						[required]="true"
						(onChange)="checkIfUtilisateurExists()"
						*ngIf="showUtilisateurSelector"
					/>
				</div>

				<div class="field col-12 md:col-6 xl:col-4">
					<label for="uti_civilite">Civilité</label>
					<civilite-selector
						name="uti_civilite"
						#uti_civilite
						[(ngModel)]="utilisateur.uti_civilite"
						required
						[readonly]="!editMode"
						[disabled]="formDisabled"
					></civilite-selector>
				</div>

				<div class="field col-12 md:col-6 xl:col-4">
					<label for="uti_nom">Nom</label>
					<input
						type="text"
						pInputText
						name="uti_nom"
						#uti_nom="ngModel"
						[(ngModel)]="utilisateur.uti_nom"
						required
						[ngClass]="{ 'p-invalid': submitted && uti_nom.errors }"
						[readonly]="!editMode"
						placeholder="Nom"
					>
				</div>

				<div class="field col-12 md:col-6 xl:col-4">
					<label for="uti_prenom">Prénom</label>
					<input
						type="text"
						pInputText
						name="uti_prenom"
						#uti_prenom="ngModel"
						[(ngModel)]="utilisateur.uti_prenom"
						required
						[ngClass]="{ 'p-invalid': submitted && uti_prenom.errors }"
						[readonly]="!editMode"
						placeholder="Prénom"
					>
				</div>

				<div class="field col-12 md:col-6 xl:col-4">
					<label for="uti_telephone_fixe">Téléphone fixe</label>
					<input
						type="text"
						pInputText
						name="uti_telephone_fixe"
						#uti_telephone_fixe="ngModel"
						[(ngModel)]="utilisateur.uti_telephone_fixe"
						[readonly]="!editMode"
					>
				</div>

				<div class="field col-12 md:col-6 xl:col-4">
					<label for="uti_telephone_mobile">Téléphone mobile</label>
					<input
						type="text"
						pInputText
						name="uti_telephone_mobile"
						#uti_telephone_mobile="ngModel"
						[(ngModel)]="utilisateur.uti_telephone_mobile"
						[readonly]="!editMode"
					>
				</div>

				<div class="field col-12 md:col-6 xl:col-4">
					<label for="uti_fonction">Fonction</label>
					<input
						type="text"
						pInputText
						name="uti_fonction"
						#uti_fonction="ngModel"
						[(ngModel)]="utilisateur.uti_fonction"
						placeholder="Fonction de la personne"
						[readonly]="!editMode"
					>
				</div>

				<div class="field col-12 md:col-6 xl:col-4" *ngIf="!selfEditing && utilisateur.uti_id">
					<label for="uti_actif">Accès</label>
					<div class="field grid mb-0">
						<p-toggleButton
							name="uti_bloque"
							#uti_bloque="ngModel"
							[(ngModel)]="utilisateur.uti_bloque"
							onLabel="Bloqué"
							onIcon="pi pi-times"
							offLabel="Autorisé"
							offIcon="pi pi-check"
							[styleClass]="'p-toggle-on-danger p-toggle-off-primary'"
							[disabled]="!editMode || formDisabled"
						></p-toggleButton>
					</div>
				</div>

				<ng-container *ngIf="utilisateur.uti_id && canEdit">
					<div class="field col-fixed">
						<label>Mot de passe</label>
						<div>
							<button
								type="button"
								pButton
								label="Réinitialisation mot de passe"
								(click)="resetPassword()"
								[disabled]="loading || disableFrom"
								[loading]="reinitialisationMotDePasseLoading"
							></button>
						</div>
					</div>
					<div class="field col-fixed">
						<label>Déconnecter toutes les sessions</label>
						<div>
							<button
								type="button"
								pButton
								label="Déconnecter"
								(click)="disconnect()"
								[disabled]="loading || disableFrom"
								[loading]="disconnecting"
							></button>
						</div>
					</div>
				</ng-container>

			</div>
		</fieldset>

	</p-panel>

</form>
