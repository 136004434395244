import { Observable, Subject, Subscriber } from "rxjs";


export class Cache<T> {

	protected cached?: T
	public subject = new Subject<T>();

	constructor(value?: T) {
		if (value) this.update(value);
	}

	isEmpty() {
		return this.cached === undefined;
	}

	isNotEmpty() {
		return !this.isEmpty();
	}

	update(newValue: T) {
		this.cached = newValue;
		this.subject.next(newValue);
	}

	//currentAsObservable retourne un observable avec un seul élément dans
	//la séquence: la valeur courrante du cache. isNotEmpty doit être vérifié avant.
	currentAsObservable() {
		if (this.isEmpty()) {
			throw new Error('le cache est vide');
		}

		const cache = this.cached;

		return new Observable<T>((subscriber: Subscriber<T>) => {
			subscriber.next(cache);
			subscriber.complete();
		});
	}

	current() {
		if (this.isEmpty()) {
			throw new Error('le cache est vide');
		}
		return this.cached;
	}

	invalidate() {
		this.cached = undefined;
	}

}

// Cache qui auto expire après une certaine durée spécifiée à la construction (maxAgeMillis).
export class ExpiringCache<T> extends Cache<T> {

	constructor(readonly maxAgeMillis = 1000) {
		super();
	}

	protected timeoutHandle?: ReturnType<typeof setTimeout>;

	override update(newValue: T) {
		this.cached = newValue
		if (this.timeoutHandle !== undefined) {
			clearTimeout(this.timeoutHandle);
		}
		//Invalidation du cache après maxAgeMillis.
		this.timeoutHandle = setTimeout(() => {
			this.invalidate();
		}, this.maxAgeMillis)
	}
}
