export const environment = {
	production: true,
	version: '20250113.12576.20250110',
	api_url: '/api',
	logo_placeholder: '/assets/instance/logo-iteq-large.png',
	logo_placeholder_small: '/assets/instance/logo-iteq-small.png',
	logo_placeholder_small_bw: '/assets/instance/logo-iteq-small-placeholder.png',
	display_instance_name_on_auth_page: true,
	import_max_file_size_bytes: 10485760, // 10Mo
	logo_max_file_size_bytes: 10485760, // 10Mo
	document_convention_max_file_size_bytes: 20971520, // 20Mo
	fichier_fournisseur_max_file_size_bytes: 104857600, // 100Mo
	fichier_fournisseur_file_limit: 200,
	media_max_file_size_bytes: 104857600, // 100Mo
	media_file_limit: 200,
	auto_refresh_notif_admin_counter: false,
	password_pattern: '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})',
	nb_actu_dashboard: 6,
	nb_tuto_dashboard: 6,
	default_rows: 10,
	optionsExpirationMDP: [
		{label: '3', value: 3},
		{label: '6', value: 6},
		{label: '9', value: 9},
		{label: '12', value: 12},
	]
};
