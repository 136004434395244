import { NgModule, Component, OnInit, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StateService } from '@uirouter/core';

import { Subscription } from 'rxjs';

import { ButtonModule} from 'primeng/button';
import { DialogService } from 'primeng/dynamicdialog';
import { MenuItem } from 'primeng/api';
import { MenuModule } from '@app/primeng-overrides/menu';

import { Contexte } from '@app/contexte/contexte.model';
import { EventManagerService } from '@global/event-manager.service';
import { LogoDisplayModule } from '@app/logo/logo-display';
import { ParametreService } from '@app/parametre/parametre.service';
import { PortailService } from '@app/portail/portail.service';
import { ProfilDialogComponent } from '@app/utilisateur/utilisateur-form';
import { RGPDDialogComponent } from '@app/utilisateur/rgpd-dialog';
import { StorageService } from '@global/storage.service';
import { Utilisateur } from '@app/utilisateur/utilisateur.model';
import { UtilisateurService } from '@app/utilisateur/utilisateur.service';
import { UtilisateurPipe } from '@app/utilisateur/utilisateur.pipe';

@Component({
	selector: 'app-topbar',
	templateUrl: './app-topbar.html'
})
export class AppTopbarComponent implements OnInit, OnDestroy {

	version: string;
	currentUtilisateur: Utilisateur;
	subscriptionCurrentUtilisateur: Subscription;
	currentUtilisateurMenuItems: MenuItem[];

	subscriptionMenuItems: Subscription;
	hasMenu: boolean;

	subscriptionCurrentContexte: Subscription;
	currentContexte: Contexte;
	ctx_libelle: string;
	rgpdDialogShown: boolean;
	instanceName: string;

	constructor(
		private dialogService: DialogService,
		private eventManager: EventManagerService,
		private parametreService: ParametreService,
		private portailService: PortailService,
		private stateService: StateService,
		private storageService: StorageService,
		private utilisateurService: UtilisateurService,
		private utilisateurPipe: UtilisateurPipe
	) {
		this.instanceName = this.parametreService.getParam('instance_name');
		this.version = 'rev.' + this.parametreService.getParam('version');
		this.rgpdDialogShown = this.storageService.get('rgpdDialogShown');

		this.subscriptionCurrentUtilisateur = this.utilisateurService.currentUtilisateur$.subscribe(
			(utilisateur: Utilisateur) => {
				this.updateCurrentUtilisateur(utilisateur);
			}
		);

		this.subscriptionCurrentContexte = this.portailService.$currentContexte.subscribe(
			(contexte: Contexte) => {
				this.currentContexte = contexte;
				this.updateUserMenu();
			}
		);
	}

	ngOnInit(): void {

	}

	ngOnDestroy(): void {
		this.subscriptionCurrentUtilisateur.unsubscribe();
		this.subscriptionCurrentContexte.unsubscribe();
	}

	logout() {
		this.eventManager.emit('logout', true);
	}

	showMonProfilForm() {
		const ref = this.dialogService.open(ProfilDialogComponent, {
			header: 'Mon profil',
			width: '70%'
		});
		ref.onClose.subscribe(dialogResult => {
			if (dialogResult && dialogResult.refreshCurrentUtilisateur == true) {
				this.utilisateurService.getCurrentUtilisateur()
				.subscribe();
			}
		});
	}

	updateCurrentUtilisateur(utilisateur: Utilisateur) {
		this.currentUtilisateur = utilisateur;

	}

	updateUserMenu() {
		if (this.currentUtilisateur === null || this.currentUtilisateur.expiration_mot_de_passe == null) {
			this.currentUtilisateurMenuItems = [{
				label: 'Se déconnecter',
				icon: 'pi pi-sign-out',
				command: () => {
					this.logout();
				}
			}];
		}
		else {
			let items: MenuItem[] = [];

			items.push({
				label: 'Mon profil',
				icon: 'pi pi-user-edit',
				command: () => {
					this.showMonProfilForm();
				}
			});

			if (this.portailService.contextesAccessibles.length > 1 || this.portailService.portailsAccessibles.length > 1) {
				items.push({
					separator: true
				});
			}

			if (this.portailService.contextesAccessibles.length > 1) {
				let changeContexteLabel: string = 'Contextes';
				switch (this.portailService.getPorCodeOrCurrentPorCode()) {
					case 'adherent':
						changeContexteLabel= 'Filiales'
						break;
					case 'fournisseur':
					case 'frais_generaux':
						changeContexteLabel= 'Partenariats'
						break;
				}
				items.push({
					label: changeContexteLabel,
					icon: 'pi pi-sign-in',
					command: () => {
						let mainState = this.portailService.getPortailMainState();
						this.stateService.go(`${mainState}.selection_contexte`);
					}
				});
			}


			if (this.portailService.portailsAccessibles.length > 1) {
				items.push({
					label: 'Portails',
					icon: 'pi pi-sign-in',
					command: () => {
						this.stateService.go('portail');
					}
				});
			}

			items.push({
				separator: true
			});

			items.push({
				label: 'Se déconnecter',
				icon: 'pi pi-sign-out',
				command: () => {
					this.logout();
				}
			});

			this.currentUtilisateurMenuItems = [
				{
					label: this.utilisateurPipe.transform(this.currentUtilisateur),
					items: items
				}
			];

			if (this.currentUtilisateur.uti_date_accord_rgpd != null) {
				this.storageService.set('rgpdDialogShown', true);
			}
			else if (
				!this.rgpdDialogShown
				&& this.currentUtilisateur.uti_date_accord_rgpd === null
				&& this.stateService.current.name != 'portail.change_password'
			) {
				this.rgpdDialogShown = true;
				const ref = this.dialogService.open(RGPDDialogComponent, {
					header: 'Données personnelles',
					width: '70%'
				});
				ref.onClose.subscribe(dialogResult => {
					this.storageService.set('rgpdDialogShown', true);
				});
			}
		}
	}

}


@NgModule({
	imports: [
		CommonModule,
		ButtonModule,
		MenuModule,
		LogoDisplayModule
	],
	exports: [AppTopbarComponent],
	declarations: [AppTopbarComponent]
})
export class AppTopbarModule { }
