import { Injectable } from '@angular/core';
import {
	getParams,
	MatchResult,
	parseUrl,
	StateObject,
	StateRegistry,
	StateRule,
	StateDeclaration,
	StateService,
	TargetState,
	UrlParts,
	UrlService,
} from '@uirouter/core';

import { InternalLinkPipe } from '@helpers/internal-link.pipe';

@Injectable({providedIn: 'root'})
export class StateUtilsService {


	constructor(
		private internalLinkPipe: InternalLinkPipe,
		private stateRegistry: StateRegistry,
		private stateService: StateService,
		private urlService: UrlService,
	) {

	}

	getFirstNonAbstractParent(state: any): StateDeclaration {
		let parent: StateDeclaration = this.stateService.get('^', state);
		while (!!parent.abstract || parent.redirectTo == state.name) {
			parent = this.stateService.get('^', parent);
		}
		return parent;
	}

	matchUrlToState(url: string): TargetState|null {
		let formattedUrl = this.internalLinkPipe.transform(url);

		const parts: UrlParts = {
			path: parseUrl(formattedUrl).path,
			// search: getParams(parseUrl(formattedUrl).search),
			hash: parseUrl(formattedUrl).hash,
		}

		const best: MatchResult = this.urlService.match(parts);

		const rule = best && best.rule;
		// If the best match is a state, get the params
		if (rule && rule.type === 'STATE') {
			const state = (rule as StateRule).state;
			const params = best.match;

			// reaffect the search params, because... weird...
			if (parseUrl(formattedUrl).search) {
				Object.assign(params, getParams(parseUrl(formattedUrl).search));
			}

			return this.stateService.target(state, params);
		}
		return null;
	}

	returnToState(returnTo: TargetState) {
		const state = returnTo.state();
		const params = returnTo.params();
		const options = Object.assign({}, returnTo.options(), { reload: true });
		if (!!!state || state.name && state.name.startsWith('auth')) {
			this.stateService.go('portail', undefined, {reload: true});
		}
		else {
			this.stateService.go(state, params, options);
		}
	}

}

