import {
	ControlValueAccessor
} from '@angular/forms';


export abstract class ControlValueAccessorBase<T> implements ControlValueAccessor {

	abstract innerValue: T;

	onChange: any = () => { };
	onTouched: any = () => { };

	get value(): T {
		return this.innerValue;
	}

	// Important: il est recommandé de surcharger le getter si le setter est surchargé.
	set value(value: T) {
		this.innerValue = value;
		this.onChange(value);
		this.onTouched();
	}

	registerOnChange(fn: Function) {
		this.onChange = fn;
	}

	registerOnTouched(fn: Function) {
		this.onTouched = fn;
	}

	writeValue(value: T) {
		if (typeof value != 'undefined') {
			this.innerValue = value;
		}
	}
}
