import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';

import { map, Observable, tap } from 'rxjs';

import { ListesGeneriquesService } from '@app/_global/listes-generiques.service';
import { Cache, ExpiringCache } from '@app/_helpers/cache';
import { prepareQueryParams, prepareQueryParamsForDownload } from '@app/_helpers/prepare-query-params';
import { TauxTVA } from '@app/salons/taux-tva/taux-tva.model';
import { environment } from '@environments/environment';
import { IEventListener } from '@global/event-manager.service';
import {
	areSameDayDates,
	convertDateFieldsToDate,
	convertDateFieldsToString,
	endOfDay,
	ExtensibleObject,
	numericSort,
	simpleDateTimeToString,
	startOfDay,
	uid
} from '@helpers/utils';
import { ArticleSalons } from './articles/article-salon.model';
import { CategorieArticles, CategorieArticlesRow } from './articles/categorie-articles.model';
import { ChoixFormation } from './choix-formations/choix-formation.model';
import { CommandeSalon, CreationCommandeSalon } from './commandes/commande.model';
import { ContexteSalon, ContexteSalonEditableFields, ReferentSalon } from './contextes-salons/contexte-salon.model';
import { Formateur, Formation, FormationGeneralInfo } from './formations/formations.model';
import { ModeleCommandeSalon } from './modele-commande/modele-commande.model';
import { ParticipantSalon } from './participants/participant.model';
import { SalleFormation } from './salles-formations/salle-formation.model';
import { CodeTypeParticipantSalon, CreneauHoraireSalon, CreneauPresenceSalon, EtatTypeModuleSalon, EvenementSalon, ModuleSalon, ModuleSalonPossible, PortailSalon, Salon, TypeCreneauHoraire, TypeParticipantSalon } from './salon.model';
import { SessionFormation } from './sessions-formations/session-formation.model';

const PARTICIPANTS_CACHE_TIMEOUT = 30_000;
const SALLES_FORMATIONS_CACHE_TIMEOUT = 30_000;

export interface MutationsModulesPortails {
	organisateurs: {
		portails_salons: number[],
		modules_salons: {msp_id: number, mos_id?: number, etm_id?: number}[],
	}
	exposants: {
		portails_salons: number[],
		modules_salons: {msp_id: number, mos_id?: number, etm_id?: number}[],
	};
	visiteurs: {
		portails_salons: number[],
		modules_salons: {msp_id: number, mos_id?: number, etm_id?: number}[],
	};
}

export interface ParametresPortail {
	por_id: number;
	psa_participants_nombre_defaut: number;
}

export interface EvenementsSalons {
	inscription_exposants: EvenementSalon
	inscription_visiteurs: EvenementSalon
	creation_formation?: EvenementSalon
	inscription_manuelle_formation?: EvenementSalon
}

interface CacheTauxTVA {
	total: number,
	tvas: TauxTVA[]
}

interface CacheCategoriesArticles {
	total: number,
	categories: CategorieArticles[]
}

interface CacheArticleSalons {
	total: number,
	articles: ArticleSalons[]
}

interface CacheListeSalons {
	total: number,
	salons: Salon[]
}

interface CacheModeleCommandesSalons {
	total: number,
	modeles_commandes: ModeleCommandeSalon[]
}

interface CacheCommandesSalons {
	total: number,
	commandes: CommandeSalon[]
}

interface CreneauxPresencesSalons {
	total: number;
	creneaux_presences: CreneauPresenceSalon[];
}

interface CacheParticipants {
	total: number;
	participants: ParticipantSalon[];
}

export interface SallesFormations {
	total: number;
	salles_formations: SalleFormation[];
}


@Injectable({ providedIn: 'root' })
export class SalonService implements IEventListener {

	private _uuid: string = uid();
	get uuid(): string { return this._uuid; }

	private cacheTauxTva = new Cache<CacheTauxTVA>();
	private updatingTauxTVA = false; // Empêche la duplication de requête.

	private cacheCategoriesArticles = new Cache<CacheCategoriesArticles>();
	private updatingCategories = false; // Empêche la duplication de requête.

	private cacheListeSalons = new Cache<CacheListeSalons>();

	private cacheArticlesSalons = new Cache<CacheArticleSalons>();
	private cacheModulesSalonsPossibles = new Cache<ModuleSalonPossible[]>();
	private updatingModulesSalonsPossible = false; // Empêche la duplication de requête.

	private cacheModeleCommandesSalons = new Map</*sal_id*/ number, Cache<CacheModeleCommandesSalons>>();
	private cacheCommandesSalons = new Map</*sal_id*/ number, Cache<CacheCommandesSalons>>();

	private cacheParticipants = new Map</*sal_id*/ number, ExpiringCache<CacheParticipants>>;
	private cacheSallesDeFormation = new Map</*sal_id*/ number, ExpiringCache<SallesFormations>>;

	readonly typesParticipantsSalons: ReadonlyArray<{tps_code: string, tps_libelle: string}>;
	readonly typesCreneauxHoraires: ReadonlyArray<TypeCreneauHoraire>;
	readonly etatsTypesModuleSalons: ReadonlyArray<EtatTypeModuleSalon>;
	readonly etatsModuleFormateur: ReadonlyArray<EtatTypeModuleSalon>;

	constructor(
		private http: HttpClient,
		@Inject(LOCALE_ID) public locale: string,
		private listesGeneriquesService: ListesGeneriquesService,
	) {
		this.typesParticipantsSalons = structuredClone(this.listesGeneriquesService.getListe('types_participants_salons'));
		this.typesCreneauxHoraires = structuredClone(this.listesGeneriquesService.getListe('types_creneaux_horaires'));
		this.typesCreneauxHoraires.forEach((type: any) => type.tch_id = Number.parseInt(type.tch_id));

		this.etatsTypesModuleSalons = structuredClone(this.listesGeneriquesService.getListe('etats_types_modules_salons'));

		let etatsModuleFormateur = this.etatsTypesModuleSalons.filter(etat => etat.tms_code == 'formateur');
		numericSort(etatsModuleFormateur, false, 'etm_id');
		this.etatsModuleFormateur = etatsModuleFormateur;
	}

	ngOnDestroy(): void {
		//TODO: désinscrire les callbacks d'events
	}

	/* ====== Taux de TVA ====== */

	public getCacheTVAs(refresh?: boolean) {
		if (refresh !== true && this.cacheTauxTva.isNotEmpty()) {
			return this.cacheTauxTva.currentAsObservable();
		}
		return this.getTVAs()
			.pipe(map(result => {
				this.cacheTauxTva.update(result);
				return result;
			})
		);
	}

	public getTVAs(params?: unknown) {
		const tmpParams = prepareQueryParams(params);

		return this.http.get<any>(`${environment.api_url}/taux_tva`, tmpParams)
			.pipe(
				map(({ total, taux_tva }) => {
					const result = {
						total: total,
						tvas: Array.from(taux_tva).map((data: any) => new TauxTVA(data))
					};

					return result;
				})
			);
	}

	public getCacheTVA(tva_id: number, refresh?: boolean) {
		if (refresh !== true && this.cacheTauxTva.isNotEmpty()) {
			const tva = this.cacheTauxTva.current()!.tvas.find(tva => tva.tva_id == tva_id);
			if (tva) {
				return new Observable<TauxTVA>(subscriber => {
					subscriber.next(tva);
					subscriber.complete();
				});
			}
		}
		let observable: Observable<CacheTauxTVA>;
		if (this.updatingTauxTVA) {
			// On attend la prochaine valeur du cache.
			observable = this.cacheTauxTva.subject;
		}
		else {
			this.updatingTauxTVA = true;
			observable =
				this.getTVAs()
				.pipe(tap(result => {
					this.updatingTauxTVA = false;
					this.cacheTauxTva.update(result);
				}));
		}

		return observable.pipe(map(result => {
			return result.tvas.find(tva => tva.tva_id == tva_id);
		}));
	}

	public getTVA(tva_id: number) {
		return this.http.get<any>(`${environment.api_url}/taux_tva/${tva_id}`)
			.pipe(map(data => new TauxTVA(data)));
	}

	public createTVA(data: TauxTVA) {
		this.cacheTauxTva.invalidate();

		return this.http.post<any>(`${environment.api_url}/taux_tva`, {
			tva_libelle: data.tva_libelle,
			tva_actif: data.tva_actif,
			tva_taux: data.tva_taux,
		});
	}

	public editTVA(tva: TauxTVA) {
		this.cacheTauxTva.invalidate();

		return this.http.put<any>(`${environment.api_url}/taux_tva/${tva.tva_id}`, {
			tva_libelle: tva.tva_libelle,
			tva_actif: tva.tva_actif,
			tva_taux: tva.tva_taux,
		});
	}

	public deleteTVA(tva_id: number) {
		this.cacheTauxTva.invalidate();

		return this.http.delete<any>(`${environment.api_url}/taux_tva/${tva_id}`);
	}

	/* ====== Catégories articles ====== */

	public getCacheCategoriesArticles(params?: ExtensibleObject, refresh?: boolean) {
		let useCache = (params == undefined) || allPropertiesNullOrUndefined(params);

		if (useCache && refresh !== true && this.cacheCategoriesArticles.isNotEmpty()) {
			return this.cacheCategoriesArticles.currentAsObservable();
		}
		return (
			this.getCategoriesArticles()
			.pipe(map(result => {
				if (useCache) {
					this.cacheCategoriesArticles.update(result);
				}
				return result;
			}))
		);
	}

	public getCategoriesArticles(params?: unknown) {
		const tmpParams = prepareQueryParams(params);

		return this.http.get<any>(`${environment.api_url}/categories_articles_salons`, tmpParams)
			.pipe(
				map(({ total, categories_articles_salons }) => {
					const result = {
						total: total,
						categories: Array.from(categories_articles_salons).map((data: any) => {
							const categorie: CategorieArticles = {
								...data
							};
							return categorie;
						})
					};
					return result;
				})
			);
	}

	public getCacheCategorieArticles(cas_id: number) {
		if (this.cacheCategoriesArticles.isNotEmpty()) {
			const categorie = this.cacheCategoriesArticles.current()!.categories.find((categorie: CategorieArticles) => categorie.cas_id == cas_id);
			if (categorie) {
				return new Observable<CategorieArticlesRow>(subscriber => {
					subscriber.next(categorie);
					subscriber.complete();
				});
			}
		}

		let observable: Observable<CacheCategoriesArticles>;
		if (this.updatingCategories) {
			// On attend la prochaine valeur du cache.
			observable = this.cacheCategoriesArticles.subject;
		} else {
			this.updatingCategories = true;
			observable =
				this.getCategoriesArticles(cas_id)
				.pipe(tap(result => {
					this.updatingCategories = false;
					this.cacheCategoriesArticles.update(result);
				}));
		}

		return observable.pipe(map(result => {
			return result.categories.find(categorie => categorie.cas_id == cas_id);
		}));
	}

	public getCategorieArticles(cas_id: number): Observable<CategorieArticlesRow> {
		return this.http.get<CategorieArticlesRow>(`${environment.api_url}/categories_articles_salons/${cas_id}`);
	}

	public createCategorieArticles(data: Omit<CategorieArticlesRow, 'cas_id'>) {
		this.cacheCategoriesArticles.invalidate();

		return this.http.post<any>(`${environment.api_url}/categories_articles_salons`, {
			cas_libelle: data.cas_libelle,
			cas_ordre: data.cas_ordre,
			cas_type_formulaire: data.cas_type_formulaire,
			cas_description: data.cas_description
		});
	}

	public editCategorieArticle(categorie: CategorieArticlesRow) {
		this.cacheCategoriesArticles.invalidate();

		return this.http.put<any>(`${environment.api_url}/categories_articles_salons/${categorie.cas_id}`, {
			cas_libelle: categorie.cas_libelle,
			cas_ordre: categorie.cas_ordre,
			cas_type_formulaire: categorie.cas_type_formulaire,
			cas_description: categorie.cas_description
		});
	}

	public deleteCategorieArticles(cas_id: number) {
		this.cacheCategoriesArticles.invalidate();

		return this.http.delete<any>(`${environment.api_url}/categories_articles_salons/${cas_id}`);
	}

	/* ====== Articles (non associés à un salon)====== */

	public getCacheArticlesSalons(refresh?: boolean) {
		if (refresh !== true && this.cacheArticlesSalons.isNotEmpty()) {
			return this.cacheArticlesSalons.currentAsObservable();
		}
		return this.getArticlesSalons();
	}

	public getArticlesSalons(params?: unknown) {
		const tmpParams = prepareQueryParams(params);

		return this.http.get<any>(`${environment.api_url}/articles_salons`, tmpParams)
			.pipe(
				map(({ total, articles_salons }) => {
					const result = {
						total: total,
						articles: Array.from(articles_salons).map((data: any) => {
							const article: ArticleSalons = {
								...data
							};
							return article;
						})
					};

					return result;
				})
			);
	}

	public getArticleSalons(ars_id: number) {
		return this.http.get<any>(`${environment.api_url}/articles_salons/${ars_id}`) as Observable<ArticleSalons>;
	}

	public createArticleSalons(data: Omit<ArticleSalons, 'ars_id'>) {
		this.cacheArticlesSalons.invalidate();

		return this.http.post<any>(`${environment.api_url}/articles_salons`, {
			...data
		});
	}

	public editArticleSalons(article: ArticleSalons) {
		this.cacheArticlesSalons.invalidate();

		return this.http.put<any>(`${environment.api_url}/articles_salons/${article.ars_id}`, {
			...article
		});
	}

	public deleteArticleSalons(ars_id: number) {
		this.cacheArticlesSalons.invalidate();

		return this.http.delete<any>(`${environment.api_url}/articles_salons/${ars_id}`);
	}

	/* ====== Salons ====== */

	public getSalons(params?: unknown) {
		const tmpParams = prepareQueryParams(params);

		return this.http.get<any>(`${environment.api_url}/salons`, tmpParams)
			.pipe(
				map(({ total, salons }) => {
					let result  = {
						total: total,
						salons: Array.from(salons).map(this.prepareSalonFromServer)
					};
					return result;
				})
			);
	}

	public getSalon(sal_id: number) {
		return this.http.get<any>(`${environment.api_url}/salons/${sal_id}`)
			.pipe(map((data: unknown) => this.prepareSalonFromServer(data)));
	}

	public createSalon(data: Salon, moduleAndPortailIds: MutationsModulesPortails, parametresPortails: ParametresPortail[], evenements: EvenementsSalons) {
		this.cacheListeSalons.invalidate();

		const body = this.prepareSalonForServer(data, moduleAndPortailIds, parametresPortails, evenements);

		return this.http.post<any>(`${environment.api_url}/salons`, body);
	}

	public editSalon(salon: Salon, moduleAndPortailIds: MutationsModulesPortails, parametresPortails: ParametresPortail[], evenements: EvenementsSalons) {
		this.cacheListeSalons.invalidate();

		const body = this.prepareSalonForServer(salon, moduleAndPortailIds, parametresPortails, evenements);

		return this.http.put<any>(`${environment.api_url}/salons/${salon.sal_id}`, body);
	}

	public prepareSalonFromServer(data: unknown) {
		const prepared = structuredClone(data) as Salon;
		convertDateFieldsToDate(prepared);
		prepared.sal_date_debut = startOfDay(prepared.sal_date_debut);
		prepared.sal_date_fin = endOfDay(prepared.sal_date_fin);

		prepared.evenements_salons ??= [];

		prepared.types_salles_formations ??= [];
		prepared.types_salles_formations.forEach(typeSalle => typeSalle.sal_id = prepared.sal_id);

		prepared.niveaux_formations ??= [];
		prepared.niveaux_formations.forEach(niveau => niveau.sal_id = prepared.sal_id);

		prepared.domaines_formations ??= [];
		prepared.domaines_formations.forEach(domaine => domaine.sal_id = prepared.sal_id);

		prepared.durees_creneaux_salons ??= [];

		for (let duree of prepared.durees_creneaux_salons) {
			let type = this.typesCreneauxHoraires.find((type: TypeCreneauHoraire) => type.tch_id == duree.tch_id)!;
			duree.tch_libelle = type.tch_libelle;
		}

		return prepared;
	}

	public prepareSalonForServer(
		salon: Salon,
		moduleAndPortailIds: MutationsModulesPortails,
		parametresPortails: ParametresPortail[],
		evenements: EvenementsSalons
	) {
		const body = structuredClone(salon) as ExtensibleObject;

		body.organisateurs = {
			portails_salons: [],
			modules_salons: structuredClone(moduleAndPortailIds.organisateurs.modules_salons)
		};
		body.exposants = {
			portails_salons: [],
			modules_salons: structuredClone(moduleAndPortailIds.exposants.modules_salons)
		};
		body.visiteurs = {
			portails_salons: [],
			modules_salons: structuredClone(moduleAndPortailIds.visiteurs.modules_salons)
		};

		// Portails

		for (let fieldName of ['organisateurs', 'exposants', 'visiteurs'] as const) {
			let previousPortailsSalons = salon[fieldName]?.portails_salons ?? [];
			let newPortailIds = moduleAndPortailIds[fieldName].portails_salons;

			// On enlève les portails qui ne sont plus présents.
			body[fieldName].portails_salons =
				previousPortailsSalons.filter((portail: PortailSalon) => newPortailIds.includes(portail.por_id));

			let portailSalons: PortailSalon[] = body[fieldName].portails_salons;

			// On ajoute les portails qui viennent d'être ajoutés.
			for(let portailId of newPortailIds) {
				if (!previousPortailsSalons.find((portail: PortailSalon) => portail.por_id == portailId)) {
					body[fieldName].portails_salons.push({
						por_id: portailId,
					});
				}
			}

			// On met à jour les paramètres des portails
			for (let parametresPortail of parametresPortails) {
				let portail = portailSalons.find((portail: PortailSalon) => portail.por_id == parametresPortail.por_id);
				if (portail) {
					Object.assign(portail, parametresPortail);
				}
			}
		}

		// Évènement salons

		let evenementSalons: Partial<EvenementSalon>[] = [
			{
				... evenements.inscription_exposants,
				tes_code: 'inscription_exposant',
			},
			{
				... evenements.inscription_visiteurs,
				tes_code: 'inscription_visiteur',
			}
		];

		let creation_formation = evenements.creation_formation;

		if (creation_formation != undefined
			&& creation_formation.evs_date_debut != undefined
			&& creation_formation.evs_date_fin != undefined) {
			evenementSalons.push({
				...creation_formation,
				tes_code: 'creation_formation',
			});
		}
		let inscription_manuelle_formation = evenements.inscription_manuelle_formation;

		if (inscription_manuelle_formation != undefined
			&& inscription_manuelle_formation.evs_date_debut != undefined
			&& inscription_manuelle_formation.evs_date_fin != undefined) {
			evenementSalons.push({
				...inscription_manuelle_formation,
				tes_code: 'inscription_manuelle_formation',
			});
		}
		body.evenements_salons = evenementSalons;
		convertDateFieldsToString(body);

		return body;
	}

	public deleteSalon(sal_id: number) {
		this.cacheListeSalons.invalidate();

		return this.http.delete<any>(`${environment.api_url}/salons/${sal_id}`);
	}

	/* ====== Modules ====== */

	public getModulesSalonsPossibles() {
		//On utilise forcément le cache car les modules possibles ne changent jamais.

		if (this.cacheModulesSalonsPossibles.isNotEmpty()) {
			return this.cacheModulesSalonsPossibles.currentAsObservable();
		}

		let observable: Observable<ModuleSalonPossible[]>;
		if (this.updatingModulesSalonsPossible) {
			// On attend la prochaine valeur du cache.
			observable = this.cacheModulesSalonsPossibles.subject;
		} else {
			this.updatingModulesSalonsPossible = true;
			observable = this.http.get<any>(`${environment.api_url}/modules_salons_possibles`)
				.pipe(map(result => {
					this.updatingModulesSalonsPossible = false;
					this.cacheModulesSalonsPossibles.update(result.modules_salons_possibles);
					return result.modules_salons_possibles as ModuleSalonPossible[];
				}));
		}

		return observable;
	}

	/* ====== Types commandes ====== */

	public getModeleCommandes(sal_id: number, mcs_id: number){
		return this.http.get<any>(`${environment.api_url}/salons/${sal_id}/modeles_commandes/${mcs_id}`) as Observable<ModeleCommandeSalon>;
	}

	public getCacheModelesCommandes(sal_id: number, refresh?: boolean) {
		let cache = this.cacheModeleCommandesSalons.get(sal_id)
		if (refresh !== true && cache && cache.isNotEmpty()) {
			return cache.currentAsObservable()
		}

		return this.getModelesCommandes(sal_id)
	}

	public getModelesCommandes(sal_id: number, params?: unknown) {
		const tmpParams = prepareQueryParams(params);

		return this.http.get<any>(`${environment.api_url}/salons/${sal_id}/modeles_commandes`, tmpParams)
			.pipe(
				map(({ total, modeles_commandes }) => {
					const result = {
						total: total,
						modeles_commandes: Array.from(modeles_commandes) as ModeleCommandeSalon[]
					};
					if (!params) {
						let cache = new Cache<CacheModeleCommandesSalons>(result);
						this.cacheModeleCommandesSalons.set(sal_id, cache);
					}
					return result;
				})
			);
	}


	public createModeleCommande(modeleCommande: ModeleCommandeSalon, sal_id: number){
		this.cacheModeleCommandesSalons.get(sal_id)?.invalidate();

		return this.http.post(`${environment.api_url}/salons/${sal_id}/modeles_commandes`, modeleCommande);
	}

	public editModeleCommande(modeleCommande: ModeleCommandeSalon, sal_id: number, isPartialEdit = false){
		this.cacheModeleCommandesSalons.get(sal_id)?.invalidate();

		let body: unknown = modeleCommande;

		if (isPartialEdit) {
			body = {
				mcs_libelle: modeleCommande.mcs_libelle,
				mcs_description: modeleCommande.mcs_description,
			};
		}

		return this.http.put(`${environment.api_url}/salons/${sal_id}/modeles_commandes/${modeleCommande.mcs_id}`, body);
	}

	public deleteModeleCommande(mcs_id: number, sal_id: number){
		this.cacheModeleCommandesSalons.get(sal_id)?.invalidate();

		return this.http.delete(`${environment.api_url}/salons/${sal_id}/modeles_commandes/${mcs_id}`);
	}

	/* ====== Commandes ====== */

	public getCommande(sal_id: number, cms_id: number){
		return (
			this.http.get<any>(`${environment.api_url}/salons/${sal_id}/commandes/${cms_id}`)
			.pipe(map((data: any) => this.prepareCommandeFromServer(data)))
		);
	}

	public getCommandes(sal_id: number, params?: unknown) {
		const tmpParams = prepareQueryParams(params);

		return this.http.get<any>(`${environment.api_url}/salons/${sal_id}/commandes`, tmpParams)
			.pipe(
				map(({ total, commandes }) => {
					let result = {
						total: total,
						commandes: Array.from(commandes).map(this.prepareCommandeFromServer)
					};
					return result;
				})
			);
	}

	public createCommande(commande: CreationCommandeSalon, sal_id: number){
		this.cacheCommandesSalons.get(sal_id)?.invalidate();

		let body = structuredClone(commande);
		convertDateFieldsToString(body);

		return this.http.post(`${environment.api_url}/salons/${sal_id}/commandes`, body);
	}

	public editCommande(commande: CommandeSalon, sal_id: number){
		this.cacheCommandesSalons.get(sal_id)?.invalidate();
		let body = this.prepareCommandeForServer(commande);

		return this.http.put(`${environment.api_url}/salons/${sal_id}/commandes/${commande.cms_id}`, body);
	}

	public setCommandeValidation(cms_id: number, sal_id: number, validate: boolean){
		this.cacheCommandesSalons.get(sal_id)?.invalidate();
		return this.http.put(`${environment.api_url}/salons/${sal_id}/commandes/${cms_id}`, {validation: validate});
	}

	public setCommandeBlock(cms_ids: number[], sal_id: number, block: boolean){
		this.cacheCommandesSalons.get(sal_id)?.invalidate();
		return this.http.put(`${environment.api_url}/salons/${sal_id}/commandes/blocage`, {blocage: block, cms_ids: cms_ids});
	}

	public deleteCommande(cms_id: number, sal_id: number){
		this.cacheCommandesSalons.get(sal_id)?.invalidate();

		return this.http.delete(`${environment.api_url}/salons/${sal_id}/commandes/${cms_id}`);
	}

	public prepareCommandeFromServer(data: unknown) {
		let prepared = structuredClone(data);
		convertDateFieldsToDate(prepared);
		return prepared as CommandeSalon;
	}

	public prepareCommandeForServer(commande: CommandeSalon) {
		let body = structuredClone(commande) as ExtensibleObject;
		convertDateFieldsToString(body);
		return body;
	}

	public exportCommandesSalons(tps_code: CodeTypeParticipantSalon, sal_id: number){
		let tmpParams = prepareQueryParamsForDownload({tps_code: tps_code});
		return this.http.get<any>(`${environment.api_url}/salons/${sal_id}/commandes/export`, tmpParams);
	}

	public exportArticlesCommandesSalons(tps_code: CodeTypeParticipantSalon, sal_id: number){
		let tmpParams = prepareQueryParamsForDownload({tps_code: tps_code});
		return this.http.get<any>(`${environment.api_url}/salons/${sal_id}/commandes/export_articles`, tmpParams);
	}

	/* ====== Contextes salons ====== */

	public createContexteSalon(ctx_id: number, sal_id: number){
		return this.http.post(`${environment.api_url}/salons/${sal_id}/contextes/${ctx_id}`, {});
	}

	public createContextesSalons(ctx_ids: number[], sal_id: number){
		return this.http.post(`${environment.api_url}/salons/${sal_id}/contextes`, {
			ctx_ids: ctx_ids
		});
	}

	public getContexteSalons(sal_id: number, params?: unknown){
		const tmpParams = prepareQueryParams(params);

		return this.http.get(`${environment.api_url}/salons/${sal_id}/contextes`, tmpParams)
			.pipe(map((data: any) => {

				const result = {
					total: data.total,
					contextes: Array.from(data.contextes_salons).map(this.prepareContexteSalonFromServer)
				};
				return result;
			}))
		;
	}

	public getContexteSalon(sal_id: number, ctx_id: number){
		return (
			this.http.get(`${environment.api_url}/salons/${sal_id}/contextes/${ctx_id}`)
			.pipe(map((data: any) => {
				return this.prepareContexteSalonFromServer(data);
			}))
		);
	}

	public editContexteSalon(sal_id: number, ctx_id: number, fields: ContexteSalonEditableFields){
		return this.http.put(`${environment.api_url}/salons/${sal_id}/contextes/${ctx_id}`, fields);
	}

	public removeContexteSalon(ctx_id: number, sal_id: number){
		return this.http.delete(`${environment.api_url}/salons/${sal_id}/contextes/${ctx_id}`);
	}

	public updateReponseParticipationContexteSalon(csa_participation: boolean | null, ctx_id: number, sal_id: number){
		return this.http.put(`${environment.api_url}/salons/${sal_id}/contextes/${ctx_id}/reponse`, {csa_participation: csa_participation});
	}

	public exportContextesSalons(tps_code: CodeTypeParticipantSalon, sal_id: number){
		let tmpParams = prepareQueryParamsForDownload({tps_code: tps_code});
		return this.http.get<any>(`${environment.api_url}/salons/${sal_id}/contextes/export`, tmpParams);
	}

	private prepareContexteSalonForServer(contexte: ContexteSalon) {
		let body = structuredClone(contexte) as ExtensibleObject;
		convertDateFieldsToString(body);
		return body;
	}

	private prepareContexteSalonFromServer(data: any) {
		let prepared = structuredClone(data);
		convertDateFieldsToDate(prepared);
		return prepared as ContexteSalon;
	}

	/* ====== Référents salons ====== */

	public getReferentsSalons(sal_id: number, params?: unknown){
		const tmpParams = prepareQueryParams(params);

		return (
			this.http.get(`${environment.api_url}/salons/${sal_id}/referents`, tmpParams)
			.pipe(map((data: any) => {

				let result = {
					total: data.total,
					referents: Array.from(data.referents) as ReferentSalon[]
				};

				return result;
			}))
		);
	}

	public addReferentSalon(ctx_id: number, sal_id: number, referent: Partial<ReferentSalon>){
		let body = referent;
		return this.http.post(`${environment.api_url}/salons/${sal_id}/contextes/${ctx_id}/referents`, body);
	}

	public removeReferentSalon(uti_id: number, ctx_id: number, sal_id: number){
		return this.http.delete(`${environment.api_url}/salons/${sal_id}/contextes/${ctx_id}/referents/${uti_id}`);
	}

	public exportReferentsSalons(tps_code: CodeTypeParticipantSalon, sal_id: number, ctx_id?: number){
		let tmpParams = prepareQueryParamsForDownload({
			tps_code: tps_code,
			ctx_id: ctx_id
		});
		return this.http.get<any>(`${environment.api_url}/salons/${sal_id}/referents/export`, tmpParams);
	}

	public getLibelleTypeParticipantSalon(tpsCode: CodeTypeParticipantSalon) {
		return this.typesParticipantsSalons.find((type: TypeParticipantSalon) => type.tps_code == tpsCode)?.tps_libelle;
	}

	// Salles formations

	public getCacheSallesFormations(sal_id: number, tsf_id?: number) {
		let cache = this.cacheSallesDeFormation.get(sal_id);
		if (cache && cache.isNotEmpty()) {
			let salles = cache.currentAsObservable();
			if (tsf_id != undefined) {
				salles = salles.pipe(map(salles => {
					return {
						salles_formations: salles.salles_formations.filter((salle: SalleFormation) => salle.tsf_id == tsf_id),
						total: salles.total
					};
				}))
			}
			return salles;
		}

		return this.getSallesFormations(sal_id)
		.pipe(map((result: SallesFormations) => {
			if (cache == undefined) {
				cache = new ExpiringCache(SALLES_FORMATIONS_CACHE_TIMEOUT);
				this.cacheSallesDeFormation.set(sal_id, cache);
			}
			cache.update(result);
			return result;
		}));
	}

	public getSallesFormations(sal_id: number, params?: unknown){
		const tmpParams = prepareQueryParams(params);

		return this.http.get(`${environment.api_url}/salons/${sal_id}/salles_formations`, tmpParams)
		.pipe(map((data: any) => {

			const result = {
				total: data.total,
				salles_formations: Array.from(data.salles_formations).map(this.prepareSalleFormationFromServer)
			};
			return result;
		}))
	}

	public getSalleFormation(sal_id: number, sfo_id: number) {
		return this.http.get(`${environment.api_url}/salons/${sal_id}/salles_formations/${sfo_id}`)
		.pipe(map((result: any) => this.prepareSalleFormationFromServer(result)));
	}

	public exportSallesFormations(sal_id: number, params: ExtensibleObject = {}){
		let tmpParams = prepareQueryParamsForDownload(params);
		return this.http.get<any>(`${environment.api_url}/salons/${sal_id}/salles_formations/export`, tmpParams);
	}

	public createSalleFormation(sal_id: number, data: SalleFormation, creneauxPresencesIds: number[]) {
		this.cacheSallesDeFormation.get(sal_id)?.invalidate();

		let body: any = structuredClone(data);
		body.creneaux_presences = creneauxPresencesIds;

		delete body.tsf_libelle;
		return this.http.post<any>(`${environment.api_url}/salons/${sal_id}/salles_formations`, body);
	}

	public editSalleFormation(sal_id: number, data: SalleFormation, creneauxPresencesIds: number[]) {
		this.cacheSallesDeFormation.get(sal_id)?.invalidate();

		let body: any = structuredClone(data);
		body.creneaux_presences = creneauxPresencesIds;

		delete body.tsf_libelle;
		delete body.sessions;
		return this.http.put<any>(`${environment.api_url}/salons/${sal_id}/salles_formations/${data.sfo_id}`, body);
	}

	public deleteSalleFormation(sal_id: number, sfo_id: number) {
		this.cacheSallesDeFormation.get(sal_id)?.invalidate();

		return this.http.delete<any>(`${environment.api_url}/salons/${sal_id}/salles_formations/${sfo_id}`);
	}

	private prepareSalleFormationFromServer(data: any) {
		let prepared = structuredClone(data);
		convertDateFieldsToDate(prepared);
		return prepared as SalleFormation;
	}


	// Formations

	public getFormations(sal_id: number, params?: unknown){
		const tmpParams = prepareQueryParams(params);

		return this.http.get(`${environment.api_url}/salons/${sal_id}/formations`, tmpParams)
		.pipe(map((data: any) => {

			const result = {
				total: data.total,
				formations: Array.from(data.formations_salons).map(this.prepareFormationFromServer),
			};
			return result;
		}));
	}

	public getFormation(sal_id: number, for_id: number) {
		return this.http.get(`${environment.api_url}/salons/${sal_id}/formations/${for_id}`)
			.pipe(map((data: any) => this.prepareFormationFromServer(data)));
	}


	public exportFormations(sal_id: number, params: ExtensibleObject = {}){
		let tmpParams = prepareQueryParamsForDownload(params);
		return this.http.get<any>(`${environment.api_url}/salons/${sal_id}/formations/export`, tmpParams);
	}

	public createFormation(sal_id: number, formation: Formation) {
		let body: any = this.prepareFormationForServer(formation);
		return this.http.post<any>(`${environment.api_url}/salons/${sal_id}/formations`, body);
	}

	public editFormation(sal_id: number, formation: Formation) {
		let body: any = this.prepareFormationForServer(formation);
		return this.http.put<any>(`${environment.api_url}/salons/${sal_id}/formations/${formation.for_id}`, body);
	}

	public deleteFormation(sal_id: number, for_id: number) {
		return this.http.delete<any>(`${environment.api_url}/salons/${sal_id}/formations/${for_id}`);
	}

	private prepareFormationFromServer(data: any) {
		let prepared = structuredClone(data);
		convertDateFieldsToDate(prepared);
		return prepared as Formation;
	}

	private prepareFormationForServer(formation: Formation) {
		let body = structuredClone(formation) as ExtensibleObject;
		convertDateFieldsToString(body);

		body.formateurs = formation.formateurs.map(formateur => formateur.pas_id);
		return body;
	}

	// Sessions de formation

	public getSessionsFormations(sal_id: number, params: ExtensibleObject = {}) {
		let tmpParams = prepareQueryParams(params);

		return this.http.get(`${environment.api_url}/salons/${sal_id}/sessions_formations`, tmpParams)
		.pipe(map((data: any) => {
			const result = {
				total: data.total as number,
				sessions_formations: Array.from(data.sessions_formations).map((session: any) => this.prepareSessionFormationFromServer(session))
			};
			return result;
		}));
	}

	public getSessionFormation(sal_id: number, sef_id: number) {
		return this.http.get(`${environment.api_url}/salons/${sal_id}/sessions_formations/${sef_id}`)
		.pipe(map((data: any) => this.prepareSessionFormationFromServer(data)));
	}

	public exportSessionsFormations(sal_id: number, params: ExtensibleObject = {}){
		let tmpParams = prepareQueryParamsForDownload(params);
		return this.http.get<any>(`${environment.api_url}/salons/${sal_id}/sessions_formations/export`, tmpParams);
	}

	public createSessionFormation(sal_id: number, session: SessionFormation) {
		return this.http.post(`${environment.api_url}/salons/${sal_id}/sessions_formations`, session);
	}

	public createSessionsAutomatically(sal_id: number, nombre_stagiaires_minimum: number){
		let body = { nombre_stagiaires_minimum };
		return this.http.post(`${environment.api_url}/salons/${sal_id}/sessions_formations/generation_auto`, body);
	}

	public editSessionFormation(sal_id: number, session: SessionFormation) {
		let body: ExtensibleObject = structuredClone(session);
		delete body.formateur;
		delete body.salle;
		delete body.creneau_horaire;
		delete body.salles_disponibles;
		delete body.formateurs_disponibles;

		return this.http.put<any>(`${environment.api_url}/salons/${sal_id}/sessions_formations/${session.sef_id}`, body);
	}

	public deleteSessionFormation(sal_id: number, sef_id: number) {
		return this.http.delete<any>(`${environment.api_url}/salons/${sal_id}/sessions_formations/${sef_id}`);
	}

	private prepareSessionFormationFromServer(data: any) {
		let prepared = structuredClone(data);
		convertDateFieldsToDate(prepared);
		prepared.creneau_formate = this.formatCreneauHoraireLike(prepared.creneau_horaire, true);
		prepared.debut_creneau_formate = this.formatDebutCreneauHoraire(prepared.creneau_horaire.chs_date_debut, true);
		return prepared as SessionFormation;
	}

	// Stagiaires

	public exportStagiairesFormations(sal_id: number, params: ExtensibleObject){
		let tmpParams = prepareQueryParamsForDownload(params);
		return this.http.get<any>(`${environment.api_url}/salons/${sal_id}/stagiaires_formations/export`, tmpParams);
	}

	public addStagiaireSession(sal_id: number, sef_id: number, pas_id: number){
		return this.http.post(`${environment.api_url}/salons/${sal_id}/sessions_formations/${sef_id}/stagiaires_formations`, {pas_id: pas_id});
	}

	public removeStagiaireSession(sal_id: number, sef_id: number, stf_id: number, motif: string){
		const body = {
			stf_motif_annulation: motif
		}
		return this.http.delete(`${environment.api_url}/salons/${sal_id}/sessions_formations/${sef_id}/stagiaires_formations/${stf_id}`, {body: body});
	}

	// Créneaux de présences salon

	public getCreneauxPresencesSalons(sal_id: number) {
		return this.http.get<CreneauxPresencesSalons>(`${environment.api_url}/salons/${sal_id}/creneaux_presences`)
		.pipe(map((data: any) => {

			const result = {
				total: data.total,
				creneaux_presences: Array.from(data.creneaux_presences).map(this.prepareCreneauSalonFromServer)
			};
			return result;
		}));
	}

	public createCreneauPresenceSalon(sal_id: number, cps_libelle: string, cps_date_debut: Date, cps_date_fin: Date) {
		let body: ExtensibleObject = {
			cps_libelle,
		};

		body.cps_date_debut = simpleDateTimeToString(cps_date_debut);
		body.cps_date_fin = simpleDateTimeToString(cps_date_fin);

		convertDateFieldsToString(body);

		return this.http.post<CreneauxPresencesSalons>(`${environment.api_url}/salons/${sal_id}/creneaux_presences`, body);
	}

	public editCreneauPresenceSalon(sal_id: number, creneau: CreneauPresenceSalon) {
		let body = this.prepareCreneauPresenceSalonForServer(creneau);

		return this.http.put<any>(`${environment.api_url}/salons/${sal_id}/creneaux_presences/${creneau.cps_id}`, body);
	}

	public deleteCreneauPresenceSalon(sal_id: number, cps_id: number) {
		return this.http.delete<any>(`${environment.api_url}/salons/${sal_id}/creneaux_presences/${cps_id}`);
	}

	public prepareCreneauSalonFromServer(data: unknown) {
		let prepared = structuredClone(data);
		convertDateFieldsToDate(prepared);
		return prepared as CreneauPresenceSalon;
	}

	private prepareCreneauPresenceSalonForServer(creneau: CreneauPresenceSalon) {
		let body = structuredClone(creneau) as ExtensibleObject;
		body.cps_date_debut = simpleDateTimeToString(creneau.cps_date_debut);
		body.cps_date_fin = simpleDateTimeToString(creneau.cps_date_fin);
		return body;
	}

	// Créneaux horaires

	public getCreneauxHoraires(sal_id: number, cps_id: number) {
		return this.http.get<CreneauxPresencesSalons>(`${environment.api_url}/salons/${sal_id}/creneaux_presences/${cps_id}`)
		.pipe(map((data: any) => {
			const result = {
				total: data.total,
				creneaux_horaires: Array.from(data.creneaux_presences).map(this.prepareCreneauSalonFromServer)
			};
			return result;
		}));
	}

	public createCreneauHoraire(sal_id: number, creneau: CreneauHoraireSalon) {
		let body = this.prepareCreneauHoraireForServer(creneau);

		return this.http.post<CreneauHoraireSalon>(`${environment.api_url}/salons/${sal_id}/creneaux_presences/${creneau.cps_id}/creneaux_horaires`, body);
	}

	public editCreneauHoraire(sal_id: number, creneau: CreneauHoraireSalon) {
		let body = this.prepareCreneauHoraireForServer(creneau);

		return this.http.put<any>(`${environment.api_url}/salons/${sal_id}/creneaux_presences/${creneau.cps_id}/creneaux_horaires/${creneau.chs_id}`, body);
	}

	public deleteCreneauHoraire(sal_id: number, cps_id: number, chs_id: number) {
		return this.http.delete<any>(`${environment.api_url}/salons/${sal_id}/creneaux_presences/${cps_id}/creneaux_horaires/${chs_id}`);
	}

	public prepareCreneauHoraireFromServer(data: unknown) {
		let prepared = structuredClone(data);
		convertDateFieldsToDate(prepared);
		return prepared as CreneauHoraireSalon;
	}

	private prepareCreneauHoraireForServer(creneau: CreneauHoraireSalon) {
		let body = structuredClone(creneau) as ExtensibleObject;
		body.chs_date_debut = simpleDateTimeToString(creneau.chs_date_debut)
		body.chs_date_fin = simpleDateTimeToString(creneau.chs_date_fin);

		return body;
	}

	// Participants

	public getCacheParticipants(sal_id: number, refresh = false) {
		let cache = this.cacheParticipants.get(sal_id);

		if (!refresh && cache != undefined && cache.isNotEmpty()) {
			return cache.currentAsObservable();
		}

		return this.getParticipants(sal_id, {})
		.pipe(map(result => {
			if (cache == undefined) {
				cache = new ExpiringCache(PARTICIPANTS_CACHE_TIMEOUT);
				this.cacheParticipants.set(sal_id, cache);
			}
			cache.update(result);

			return result;
		}))
	}

	public getParticipants(sal_id: number, params: ExtensibleObject = {}) {
		const tmpParams = prepareQueryParams(params);

		return this.http.get(`${environment.api_url}/salons/${sal_id}/participants`, tmpParams)
		.pipe(map((data: any) => {
			const result = {
				total: data.total,
				participants: Array.from<ParticipantSalon>(data.participants).map(this.prepareParticipantFromServer)
			};
			return result;
		}))
	}

	public getParticipantsContexte(sal_id: number, ctx_id: number) {
		return this.http.get(`${environment.api_url}/salons/${sal_id}/participants?ctx_id=${ctx_id}`)
		.pipe(map((data: any) => {
			const result = {
				total: data.total,
				participants: Array.from<ParticipantSalon>(data.participants).map(this.prepareParticipantFromServer)
			};
			return result;
		}))
	}

	public exportParticipantsSalons(sal_id: number, params: ExtensibleObject = {}){
		let tmpParams = prepareQueryParamsForDownload(params);
		return this.http.get<any>(`${environment.api_url}/salons/${sal_id}/participants/export`, tmpParams);
	}

	public exportCreneauxPresencesParticipantsSalons(sal_id: number, params: ExtensibleObject = {}){
		let tmpParams = prepareQueryParamsForDownload(params);
		return this.http.get<any>(`${environment.api_url}/salons/${sal_id}/participants/creneaux_presences/export`, tmpParams);
	}

	public getParticipant(sal_id: number, pas_id: number) {
		return this.http.get(`${environment.api_url}/salons/${sal_id}/participants/${pas_id}`)
		.pipe(map((data: any) => this.prepareParticipantFromServer(data)));
	}

	public createParticipant(sal_id: number, participant: ParticipantSalon, creneauxPresencesIds: number[]) {
		this.cacheParticipants.get(sal_id)?.invalidate();

		let body: ExtensibleObject = participant;
		body.creneaux_presences = creneauxPresencesIds;

		return this.http.post<ParticipantSalon>(`${environment.api_url}/salons/${sal_id}/contextes_salons/${participant.ctx_id}/participants`, body);
	}

	public editParticipant(sal_id: number, participant: ParticipantSalon, creneauxPresencesIds: number[]) {
		this.cacheParticipants.get(sal_id)?.invalidate();

		let body: ExtensibleObject = participant;
		body.creneaux_presences = creneauxPresencesIds;

		return this.http.put(`${environment.api_url}/salons/${sal_id}/contextes_salons/${participant.ctx_id}/participants/${participant.pas_id}`, body);
	}

	public deleteParticipant(sal_id: number, ctx_id: number, pas_id: number) {
		this.cacheParticipants.get(sal_id)?.invalidate();

		return this.http.delete(`${environment.api_url}/salons/${sal_id}/contextes_salons/${ctx_id}/participants/${pas_id}`);
	}

	public prepareParticipantFromServer(data: unknown) {
		let prepared = structuredClone(data);
		convertDateFieldsToDate(prepared);
		return prepared as ParticipantSalon;
	}

	// Formateurs

	public getParticipantsFormateurs(sal_id: number, params: ExtensibleObject = {}) {
		let tmpParams = prepareQueryParams(params);

		return this.http.get(`${environment.api_url}/salons/${sal_id}/formateurs`, tmpParams)
		.pipe(map((data: any) => {
			const result = {
				total: data.total,
				participants_formateurs: Array.from<ParticipantSalon>(data.participants_formateurs).map(this.prepareParticipantFromServer)
			};
			return result;
		}))
	}

	public getFormateursFormation(sal_id: number, for_id: number) {
		return this.http.get<Formateur[]>(`${environment.api_url}/salons/${sal_id}/formations/${for_id}/formateurs`);
	}

	public exportAllFormateurs(sal_id: number){
		let tmpParams = prepareQueryParamsForDownload({});
		return this.http.get<any>(`${environment.api_url}/salons/${sal_id}/formateurs/export`, tmpParams);
	}


	// Choix de formations

	public getChoixFormationsParticipant(sal_id: number, pas_id: number) {
		return this.http.get(`${environment.api_url}/salons/${sal_id}/participants/${pas_id}/choix_formations`)
		.pipe(map((data: any) => {
			const result = {
				nb_formations_possibles: data.nb_formations_possibles,
				choix_formations: Array.from(data.choix_formations).map((choix: any) => this.prepareChoixFormationFromServer(choix))
			};
			return result;
		}));
	}

	public prepareChoixFormationFromServer(data: unknown) {
		let prepared = structuredClone(data) as ExtensibleObject;
		convertDateFieldsToDate(prepared);

		prepared.sessions = prepared.sessions.map((sessionChoix: any) => {
			let result = {
				...sessionChoix,
				creneau_formate: this.formatCreneauHoraireLike(sessionChoix, true),
			};

			result.creneau_formate_unbreakable_space = result.creneau_formate.replace(' ', ' ');
			return result;
		})
		return prepared as ChoixFormation;
	}

	public putChoixFormationsParticipant(sal_id: number, pas_id: number, choix: ChoixFormation[]) {
		let body = {formations: choix};
		return this.http.put(`${environment.api_url}/salons/${sal_id}/participants/${pas_id}/choix_formations`, body);
	}

	public getFormationsDisponiblesParticipant(sal_id: number, pas_id: number, params: ExtensibleObject = {}) {
		let tmpParams = prepareQueryParams(params);

		return this.http.get(`${environment.api_url}/salons/${sal_id}/participants/${pas_id}/formations_disponibles`, tmpParams)
		.pipe(map((data: any) => {
			const result = {
				total: data.total,
				formations_disponibles: Array.from<FormationGeneralInfo>(data.formations_disponibles)
			};
			return result;
		}));
	}

	// Formattage

	public formatCreneauHoraireLike(creneau: {chs_date_debut: Date, chs_date_fin: Date}, includeYear: boolean){
		let {chs_date_debut, chs_date_fin} = creneau;

		let locale = this.locale;

		let dateOptions: Intl.DateTimeFormatOptions = {
			year: includeYear ? 'numeric' : undefined,
			month: '2-digit',
			day: '2-digit',
			hour: '2-digit',
			minute: '2-digit',
		}

		let result = chs_date_debut.toLocaleDateString(locale, dateOptions) + ' - ';

		if (areSameDayDates(chs_date_debut, chs_date_fin)) {
			result += chs_date_fin.toLocaleTimeString(locale, {hour: '2-digit', minute:'2-digit'});
		}
		else {
			result += chs_date_fin.toLocaleDateString(locale, dateOptions);
		}
		return result;
	}

	public formatDebutCreneauHoraire(chs_date_debut: Date, includeYear: boolean){
		let locale = this.locale;

		let dateOptions: Intl.DateTimeFormatOptions = {
			year: includeYear ? 'numeric' : undefined,
			month: '2-digit',
			day: '2-digit',
			hour: '2-digit',
			minute: '2-digit',
		}

		return chs_date_debut.toLocaleDateString(locale, dateOptions);
	}

	public getCreneauxFormateursDisponiblesSurSalle(sal_id: number, for_id: number, sfo_id: number) {
		return this.http.get(`${environment.api_url}/salons/${sal_id}/formations/${for_id}/creneaux_formateurs_disponibles_sur_salle/${sfo_id}`)
			.pipe(map((response: any) => {
				return response.map((item: any) => {
					convertDateFieldsToDate(item);
					return item;
				})
			}));
	}
}

export function isFormateurModulePresent(salon: Salon){
	return getFormateurModule(salon) != undefined;
}

export function getFormateurModule(salon: Salon): ModuleSalon | undefined {
	let modules = [...salon.exposants.modules_salons, ...salon.visiteurs.modules_salons];
	return modules.find(module => module.tms_code == 'formateur');
}

export function getStagiaireModule(salon: Salon): ModuleSalon | undefined {
	let modules = [...salon.exposants.modules_salons, ...salon.visiteurs.modules_salons];
	return modules.find(module => module.tms_code == 'stagiaire');
}

export function getCodeParticipantsFormateurs(salon: Salon): CodeTypeParticipantSalon | undefined {
	return getFormateurModule(salon)?.tps_code;
}

export function getCodeParticipantsStagiaires(salon: Salon): CodeTypeParticipantSalon | undefined {
	return getStagiaireModule(salon)?.tps_code;
}

export function getActivatedPortailCodes(salon: Salon) {
	let availablePortailCodes: string[] = [];
	salon.organisateurs.portails_salons.forEach(portail => availablePortailCodes.push(portail.por_code));
	salon.exposants.portails_salons.forEach(portail => availablePortailCodes.push(portail.por_code));
	salon.visiteurs.portails_salons.forEach(portail => availablePortailCodes.push(portail.por_code));
	return availablePortailCodes;
}

export function getActivatedPortailCodesExceptOrganizers(salon: Salon) {
	let availablePortailCodes: string[] = [];
	salon.exposants.portails_salons.forEach(portail => availablePortailCodes.push(portail.por_code));
	salon.visiteurs.portails_salons.forEach(portail => availablePortailCodes.push(portail.por_code));
	return availablePortailCodes;
}


export function getPortailsByCodeTypeParticipant(salon: Salon, type: CodeTypeParticipantSalon){
	let portails: PortailSalon[] = [];
	switch (type) {
	case 'E':
		portails = salon.exposants.portails_salons;
		break;
	case 'O':
		portails = salon.organisateurs.portails_salons;
		break;
	case 'V':
		portails = salon.visiteurs.portails_salons;
		break;
	}
	return [...portails];
}

export function formatParticipantsFormationsInfo(article: {ars_participants_nombre?: number, ars_formations_nombre?: number}){
	let s = '';
	if (article.ars_participants_nombre) {
		s += article.ars_participants_nombre + ' participant'
		if (article.ars_participants_nombre > 1) {
			s += 's';
		}
		s += ' / article'
		if (article.ars_formations_nombre) {
			s += ', '
		}
	}

	if (article.ars_formations_nombre) {
		s += article.ars_formations_nombre + ' formation'
		if (article.ars_formations_nombre > 1) {
			s += 's';
		}
		s += ' / article'
	}

	return s;
}



export function isCreneauOfTypeFormation(creneau: CreneauHoraireSalon) {
	return creneau.tch_libelle.match(/.*formation.*/i);
}

function allPropertiesNullOrUndefined(obj: ExtensibleObject) {
	for (let key in obj) {
		if (obj[key] !== undefined && obj[key] !== null) {
			return false;
		}
	}
	return true;
}
