import {NgModule,Component,Input,ChangeDetectorRef,ChangeDetectionStrategy, ViewEncapsulation} from '@angular/core';
import {trigger,state,style,transition,animate} from '@angular/animations';
import {CommonModule} from '@angular/common';
import {MenuItem} from 'primeng/api';
import { UIRouter, UIRouterModule, Transition } from '@uirouter/angular';

import { TagModule } from 'primeng/tag';

import { CompteurNotificationsAdminModule } from '@app/notification/compteur-notifications-admin';
import { SignatureReminderModule } from '@app/signature/signature-reminder';
import { AppMenuService } from '@global/app-menu.service';


export class BasePanelMenuItem {

	constructor(private ref: ChangeDetectorRef) {}

	handleClick(event: Event, item: MenuItem) {
		if (item.disabled) {
			event.preventDefault();
			return;
		}

		if (item.items) {
			item.expanded = !item.expanded;
		}

		this.ref.detectChanges();

		if (!item.url) {
			event.preventDefault();
		}

		if (item.command) {
			item.command({
				originalEvent: event,
				item: item
			});
		}
	}
}

@Component({
	selector: 'gz-panelMenuItemBadge',
	template: `
		<ng-container [ngSwitch]="badge">

			<signature-reminder
				*ngSwitchCase="'signatureReminder'"
				[menuItem]="item"
				[initialLoad]="true"
			></signature-reminder>

			<compteur-notifications-admin
				*ngSwitchCase="'notifAdminCounter'"
			></compteur-notifications-admin>

		</ng-container>

	`,
})
export class GzPanelMenuItemBadge {

	@Input() badge: string;
	@Input() item: any;

	constructor() {

	}
}

@Component({
	selector: 'gz-panelMenuSub',
	template: `
		<ul [ngClass]="{'p-submenu-list': true, 'p-panelmenu-root-submenu': root}" [@submenu]="expanded ? {value: 'visible', params: {transitionParams: transitionOptions, height: '*'}} : {value: 'hidden', params: {transitionParams: transitionOptions, height: '0'}}" role="tree">
			<ng-template ngFor let-child [ngForOf]="item.items">
				<li *ngIf="child.separator" class="p-menu-separator" role="separator">
				<li *ngIf="!child.separator" class="p-menuitem" [ngClass]="child.styleClass" [class.p-hidden]="child.visible === false" [ngStyle]="child.style">
					<a
						*ngIf="!child.state"
						[attr.href]="child.url"
						class="p-menuitem-link"
						[attr.tabindex]="!item.expanded ? null : child.disabled ? null : '0'"
						[ngClass]="{'p-disabled':child.disabled, 'p-menuitem-link-has-children': child.items, 'p-menuitem-link-active': child.active}"
						role="treeitem"
						[attr.aria-expanded]="child.expanded"
						(click)="handleClick($event,child)"
						[attr.target]="child.target"
						[attr.title]="child.title"
					>
						<span class="p-panelmenu-icon pi pi-fw" [ngClass]="{'pi-angle-right': !child.expanded,'pi-angle-down': child.expanded,'flex-order-3': arrowRight}" *ngIf="child.items"></span>
						<span class="p-menuitem-icon" [ngClass]="child.icon" *ngIf="child.icon"></span>
						<span class="p-menuitem-text flex-grow-1" *ngIf="child.escape !== false; else htmlLabel">{{child.label}}</span>
						<ng-template #htmlLabel><span class="p-menuitem-text" [innerHTML]="child.label"></span></ng-template>
						<ng-container *ngIf="child.badge">
							<gz-panelMenuItemBadge [badge]="child.badge" [item]="child"></gz-panelMenuItemBadge>
						</ng-container>
					</a>
					<a
						*ngIf="child.state"
						[uiSref]="child.state"
						[uiParams]="child.uiParams"
						uiSrefActive="p-menuitem-link-active"
						class="p-menuitem-link"
						[ngClass]="{'p-disabled':child.disabled}"
						[attr.tabindex]="!item.expanded ? null : child.disabled ? null : '0'"
						role="treeitem"
						[attr.aria-expanded]="child.expanded"
						(click)="handleClick($event,child)"
						[attr.target]="child.target"
						[attr.title]="child.title"
						[fragment]="child.fragment"
						[queryParamsHandling]="child.queryParamsHandling"
						[preserveFragment]="child.preserveFragment"
						[skipLocationChange]="child.skipLocationChange"
						[replaceUrl]="child.replaceUrl"
					>
						<span class="p-panelmenu-icon pi pi-fw" [ngClass]="{'pi-angle-right':!child.expanded,'pi-angle-down':child.expanded,'flex-order-3': arrowRight}" *ngIf="child.items"></span>
						<span class="p-menuitem-icon" [ngClass]="child.icon" *ngIf="child.icon"></span>
						<span class="p-menuitem-text flex-grow-1" *ngIf="child.escape !== false; else htmlRouteLabel">{{child.label}}</span>
						<ng-template #htmlRouteLabel><span class="p-menuitem-text" [innerHTML]="child.label"></span></ng-template>
						<ng-container *ngIf="child.badge">
							<gz-panelMenuItemBadge [badge]="child.badge" [item]="child"></gz-panelMenuItemBadge>
						</ng-container>
					</a>
					<gz-panelMenuSub [item]="child" [expanded]="child.expanded" [transitionOptions]="transitionOptions" *ngIf="child.items"></gz-panelMenuSub>
				</li>
			</ng-template>
		</ul>
	`,
	animations: [
		trigger('submenu', [
			state('hidden', style({
				height: '0',
				overflow: 'hidden'
			})),
			state('visible', style({
				height: '*'
			})),
			transition('visible <=> hidden', [style({overflow: 'hidden'}), animate('{{transitionParams}}')]),
			transition('void => *', animate(0))
		])
	],
	encapsulation: ViewEncapsulation.None
})
export class GzPanelMenuSub extends BasePanelMenuItem {

	@Input() item: MenuItem;

	@Input() expanded: boolean;

	@Input() transitionOptions: string;

	@Input() root: boolean;

	@Input() arrowRight: boolean;

	constructor(ref: ChangeDetectorRef) {
		super(ref);
	}
}

@Component({
	selector: 'gz-panelMenu',
	template: `
		<!--<div [class]="styleClass" [ngStyle]="style" [ngClass]="'p-panelmenu p-component'">-->
		<div [class]="styleClass" [ngStyle]="style" [ngClass]="{'p-panelmenu p-component': true, 'arrow-right': arrowRight, 'arrow-left': !arrowRight}">
			<ng-container *ngFor="let item of model;let f=first;let l=last;">
				<div class="p-panelmenu-panel" [ngClass]="{'p-hidden': item.visible === false}">
					<div [ngClass]="{'p-component p-panelmenu-header':true, 'p-highlight':item.expanded||item.active, 'p-disabled':item.disabled}" [class]="item.styleClass" [ngStyle]="item.style">
						<a
							*ngIf="!item.state"
							[attr.href]="item.url"
							(click)="handleClick($event,item)"
							[attr.tabindex]="item.disabled ? null : '0'"
							[attr.target]="item.target"
							[attr.title]="item.title"
							class="p-panelmenu-header-link"
							[attr.aria-expanded]="item.expanded"
							[ngClass]="{'p-menuitem-link-active': !!item.active}"
						>
							<span *ngIf="item.items" class="p-panelmenu-icon pi" [ngClass]="{'pi-chevron-right':!item.expanded,'pi-chevron-down':item.expanded,'flex-order-3': arrowRight}"></span>
							<span class="p-menuitem-icon" [ngClass]="item.icon" *ngIf="item.icon"></span>
							<span class="p-menuitem-text flex-grow-1" *ngIf="item.escape !== false; else htmlLabel">{{item.label}}</span>
							<ng-template #htmlLabel><span class="p-menuitem-text" [innerHTML]="item.label"></span></ng-template>
							<ng-container *ngIf="item.badge">
								<gz-panelMenuItemBadge [badge]="item.badge" [item]="item"></gz-panelMenuItemBadge>
							</ng-container>
						</a>
						<a
							*ngIf="item.state"
							[uiSref]="item.state"
							[queryParams]="item.queryParams"
							uiSrefActive="p-menuitem-link-active"
							(click)="handleClick($event,item)"
							[attr.target]="item.target"
							[attr.title]="item.title"
							class="p-panelmenu-header-link"
							[attr.tabindex]="item.disabled ? null : '0'"
							[fragment]="item.fragment"
							[queryParamsHandling]="item.queryParamsHandling"
							[preserveFragment]="item.preserveFragment"
							[skipLocationChange]="item.skipLocationChange"
							[replaceUrl]="item.replaceUrl"
							[state]="item.state"
						>
							<span *ngIf="item.items" class="p-panelmenu-icon pi" [ngClass]="{'pi-chevron-right':!item.expanded,'pi-chevron-down':item.expanded,'flex-order-3': arrowRight}"></span>
							<span class="p-menuitem-icon" [ngClass]="item.icon" *ngIf="item.icon"></span>
							<span class="p-menuitem-text flex-grow-1" *ngIf="item.escape !== false; else htmlRouteLabel">{{item.label}}</span>
							<ng-template #htmlRouteLabel><span class="p-menuitem-text" [innerHTML]="item.label"></span></ng-template>
							<ng-container *ngIf="item.badge">
								<gz-panelMenuItemBadge [badge]="item.badge" [item]="item"></gz-panelMenuItemBadge>
							</ng-container>
						</a>
					</div>
					<div *ngIf="item.items" class="p-toggleable-content" [@rootItem]="item.expanded ? {value: 'visible', params: {transitionParams: animating ? transitionOptions : '0ms', height: '*'}} : {value: 'hidden', params: {transitionParams: transitionOptions, height: '0'}}"  (@rootItem.done)="onToggleDone()">
						<div class="p-panelmenu-content" role="region" [attr.aria-labelledby]="item.id +'_header'">
							<gz-panelMenuSub [item]="item" [arrowRight]="arrowRight" [expanded]="true" [transitionOptions]="transitionOptions" [root]="true"></gz-panelMenuSub>
						</div>
					</div>
				</div>
			</ng-container>
		</div>
	`,
	animations: [
		trigger('rootItem', [
			state('hidden', style({
				height: '0',
				overflow: 'hidden'
			})),
			state('visible', style({
				height: '*'
			})),
			transition('visible <=> hidden', [style({overflow: 'hidden'}), animate('{{transitionParams}}')]),
			transition('void => *', animate(0))
		])
	],
	// changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	styleUrls: ['./gz-panelmenu.css']
})
export class GzPanelMenu extends BasePanelMenuItem {

	@Input() model: MenuItem[];

	@Input() style: any;

	@Input() styleClass: string;

	@Input() multiple: boolean = true;

	@Input() arrowRight: boolean;

	@Input() updateOnStateChange: boolean;

	@Input() transitionOptions: string = '400ms cubic-bezier(0.86, 0, 0.07, 1)';

	public animating: boolean;

	private deregisterRouterHook: Function;

	constructor(ref: ChangeDetectorRef, private uiRouter: UIRouter, private appMenuService: AppMenuService) {
		super(ref);
	}

	ngOnInit() {
		// mise à jour automatique du menu aux changements de states
		if (this.updateOnStateChange) {
			this.deregisterRouterHook = this.uiRouter.transitionService.onSuccess({}, (trans: Transition) => {
				this.appMenuService.updateActiveStatus(this.model);
			});
		}
	}

	ngOnDestroy() {
		if (typeof this.deregisterRouterHook != 'undefined') {
			this.deregisterRouterHook();
		}
	}

	collapseAll() {
		for(let item of this.model) {
			if (item.expanded) {
				item.expanded = false;
			}
		}
	}

	hasActiveChild(item: MenuItem): boolean {
		if (item.items) {
			return item.items.some((child: MenuItem) => {
				return this.hasActiveChild(child);
			});
		}
		return !!item.active;
	}

	handleClick(event: Event, item: MenuItem) {
		if (!this.multiple) {
			for(let modelItem of this.model) {
				if (item !== modelItem && modelItem.expanded) {
					modelItem.expanded = false;
				}
			}
		}

		for(let modelItem of this.model) {
			let has = this.hasActiveChild(modelItem);
			if (item !== modelItem && !has) {
				modelItem.active = false;
			}
		}

		this.animating = true;
		super.handleClick(event, item);
	}

	onToggleDone() {
		this.animating = false;
	}

}

@NgModule({
	imports: [
		CommonModule,
		UIRouterModule,
		TagModule,
		CompteurNotificationsAdminModule,
		SignatureReminderModule,
	],
	declarations: [
		GzPanelMenu,
		GzPanelMenuSub,
		GzPanelMenuItemBadge,
	],
	exports: [
		GzPanelMenu,
		UIRouterModule,
		TagModule
	]
})
export class GzPanelMenuModule { }
