import { NgModule, Component, Input, ViewChild, forwardRef, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
	ControlValueAccessor,
	FormControl,
	FormsModule,
	NG_VALIDATORS,
	NG_VALUE_ACCESSOR,
	NgForm,
} from '@angular/forms';

import { AutoCompleteModule, AutoComplete } from 'primeng/autocomplete';
import { DropdownModule } from '@app/primeng-overrides/dropdown';

import { EqipModule } from '@global/eqip.module';
import { Utilisateur } from '@app/utilisateur/utilisateur.model';
import { UtilisateurService } from '@app/utilisateur/utilisateur.service';
import { Observable } from 'rxjs';
import { simpleEmailValidation } from '@helpers/utils';


@Component({
	selector: 'utilisateur-selector',
	 providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => UtilisateurSelectorComponent),
			multi: true
		},
		{
			provide: NG_VALIDATORS,
			useExisting: forwardRef(() => UtilisateurSelectorComponent),
			multi: true
		}
	],
	template: `
		<p-autoComplete
			#utilisateurSelector
			field="uti_email"
			[(ngModel)]="value"
			[dataKey]="emailAsValue? 'uti_email' : 'uti_id'"
			[suggestions]="utilisateursDisponibles"
			(completeMethod)="searchUtilisateur($event)"
			[forceSelection]="true"
			[placeholder]="placeholder"
			emptyMessage="Aucun résultat"
			[showClear]="nullOption"
			[required]="required"
			[readonly]="readonly"
			*ngIf="autocomplete"
		>
			<ng-template let-utilisateur pTemplate="item">
				{{ utilisateur | utilisateur }}
			</ng-template>
			<ng-template let-value pTemplate="selectedItem">
				<ng-container *ngIf="_selected">
					{{ _selected | utilisateur }}
				</ng-container>
			</ng-template>
		</p-autoComplete>
		<p-dropdown
			[name]="name"
			[required]="required"
			[options]="options"
			[(ngModel)]="value"
			[readonly]="readonly"
			[ngClass]="{'p-readonly': readonly}"
			[disabled]="disabled"
			[placeholder]="placeholder"
			[showClear]="nullOption || editable"
			[filter]="options.length > 5 && !editable"
			filterBy="uti_nom,uti_prenom,uti_email"
			[filterPlaceholder]="filterPlaceholder"
			[optionValue]="emailAsValue? 'uti_email' : 'uti_id'"
			[editable]="editable && !_selected"
			(onBlur)="handleChange($event)"
			(onClear)="handleChange($event)"
			(onOptionSelected)="handleChange($event)"
			appendTo="body"
			*ngIf="!autocomplete"
		>
			<ng-template let-item pTemplate="item">
				{{ item | utilisateur }}
			</ng-template>
			<ng-template pTemplate="selectedItem">
				<ng-container *ngIf="_selected">
					{{ _selected | utilisateur }}
				</ng-container>
			</ng-template>
		</p-dropdown>
	`
})
export class UtilisateurSelectorComponent implements ControlValueAccessor  {

	@Input() name: string;
	@Input('value') innerValue: number|string|null;
	@Input() por_id: number;
	@Input() ctx_id: number;
	@Input() grc_id: number;
	@Input() autocomplete: boolean;
	@Input() activeOnly: boolean;
	@Input() placeholder: string = 'Sélectionnez';
	@Input() filterPlaceholder: string = 'Filtrer...';
	@Input() required: boolean;
	@Input() nullOption: boolean;
	@Input() nullIfInvalid: boolean;
	@Input() disabled: boolean;
	@Input() readonly: boolean;
	@Input() autoselectIfSingle: boolean;
	@Input() autoselectFirst: boolean;
	@Input() silentInitialChangeIfSame: boolean;
	@Input() responsablesNego: boolean = false;
	@Input() annee: number;
	@Input() editable: boolean = false;
	@Input() emailAsValue: boolean = false;

	@Output() onChange: EventEmitter<any> = new EventEmitter;

	@ViewChild('utilisateurSelector') utilisateurSelector: AutoComplete;

	utilisateursDisponibles: Utilisateur[] = [];
	options: Utilisateur[] = [];
	initialized: boolean = false;
	_selected: any;

	constructor(private utilisateurService: UtilisateurService) {

	}

	ngOnInit() {
		if (!this.autocomplete) {
			this.load();
		}
	}

	ngOnChanges(changes: any) {
		if (!this.initialized) {
			this.initialized = true;
		}
		else {
			if (
				typeof changes.grc_id != 'undefined' && changes.grc_id.currentValue != changes.grc_id.previousValue
				|| typeof changes.ctx_id != 'undefined' && changes.ctx_id.currentValue != changes.ctx_id.previousValue
				|| typeof changes.por_id != 'undefined' && changes.por_id.currentValue != changes.por_id.previousValue
				|| typeof changes.annee != 'undefined' && changes.annee.currentValue != changes.annee.previousValue
			) {
				this.load(true);
			}
		}
	}

	onModelChange: any = () => { };
	onTouched: any = () => { };

	handleChange(event: any) {
		this.onChange.emit(this.value);
	}

	get value() {
		return this.innerValue;
	}

	set value(value: number|string|null) {
		this.innerValue = value;
		if (typeof value == 'number'){
			this._selected = this.options.find((one: Utilisateur) => {return one.uti_id  == value});
		}
		else {
			this._selected = this.options.find((one: Utilisateur) => {return one.uti_email  == value});
		}
		this.onModelChange(value);
		this.onTouched();
	}

	registerOnChange(fn: Function) {
		this.onModelChange = fn;
	}

	registerOnTouched(fn: Function) {
		this.onTouched = fn;
	}

	writeValue(value: number|string|null) {
		if (typeof value != 'undefined') {
			this.innerValue = value;
		}
	}

	// communicate the inner form validation to the parent form
	validate(_: FormControl) {
		if (this.emailAsValue) {
			if ((this.value == null || this.value == '') && !this.required) {
				return null;
			}

			const valid: boolean = this.value != null && simpleEmailValidation(this.value.toString());
			return valid ? null : { uti_email: { valid: false } };
		}
		else {
			return this.value != null? null : { uti_id: { valid: false } };
		}
	}

	searchUtilisateur(event: any) {
		let params = {
			search: event.query,
			uti_actif: true,
			rows: 10
		};
		this.utilisateurService.getUtilisateurs(params, this.ctx_id, this.grc_id)
		.subscribe({
			next: (response: any) => {
				this.utilisateursDisponibles = response.utilisateurs;
			}
		});
	}

	load(setAfterRefresh?: boolean) {
		let params: any = {
			uti_actif: this.activeOnly,
			por_id: this.por_id,
//			grc_id: this.grc_id,
//			ctx_id: this.ctx_id,
			annee: this.annee
		}

		let apiCall: Observable<any>|null = null;

		if (!this.responsablesNego) {
			apiCall = this.utilisateurService.getUtilisateurs(params, this.ctx_id, this.grc_id);
		}
		else {
			if (this.annee && this.por_id) {
				apiCall = this.utilisateurService.getResponsablesNego(params);
			}
		}

		if (apiCall) {
			apiCall.subscribe({
				next: (response: any) => {
					this.options = response.utilisateurs;
					this.setInitialValue(setAfterRefresh);
				}
			});
		}
	}

	setInitialValue(setAfterRefresh?: boolean) {
		const incoming = this.innerValue;
		if (
			(typeof this.innerValue != 'undefined' || setAfterRefresh)
			&& this.options.length
		) {
			if (this.options.findIndex((one: Utilisateur) => {return one.uti_id == this.innerValue; }) < 0) {
				if (this.nullIfInvalid) {
					this.innerValue = null;
				}
				if (this.autoselectIfSingle && this.options.length === 1) {
					this.innerValue = this.options[0].uti_id;
				}
			}
		}
		if (this.autoselectFirst && !this.innerValue && this.options.length) {
			this.innerValue = this.options[0].uti_id;
		}
		if (incoming != this.innerValue || !this.silentInitialChangeIfSame) {
			this.value = this.innerValue;
		}
		this._selected = this.options.find((one: Utilisateur) => {return one.uti_id  == this.value});
	}


}


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		AutoCompleteModule,
		DropdownModule,
		EqipModule,
	],
	exports: [UtilisateurSelectorComponent],
	declarations: [UtilisateurSelectorComponent]
})
export class UtilisateurSelectorModule { }
