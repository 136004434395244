<div class="p-sidebar gz-sidebar flex flex-column justify-content-between p-3">
	<a class="app-menu-toggler" (click)="toggleMenu()">
		<i class="pi" [ngClass]="{'pi-chevron-left': !hidden, 'pi-bars': hidden}"></i>
	</a>
	<div class="overflow-y-auto">
		<gz-panelMenu styleClass="block mb-5" [arrowRight]="true" [model]="items" [multiple]="false"></gz-panelMenu>
	</div>

	<div class="text-center flex-shrink-0 pt-1" *ngIf="displayAppLogo">
		<img class="logo-sm" src="/assets/images/logo-iteq-large.png" >
	</div>
</div>
