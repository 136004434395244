<div class="flex align-items-center">
	<div class="logo mr-2" title="{{version}}">
		<img src="/assets/instance/logo-iteq-small.png" class="logo-small">
	</div>
</div>
<div class="header-title flex align-items-center">
	<div class="flex align-items-center" *ngIf="currentContexte">
		<logo-display
			[entity]="currentContexte"
			[listing]="true"
			size="sm"
			[placeholderIfNone]="false"
			class="logo-small flex align-items-center mr-2"
			*ngIf="currentContexte.por_id != 1"
		></logo-display>
		<h1 >
			{{currentContexte.ctx_libelle}}
		</h1>
	</div>
	<h1 *ngIf="!currentContexte">Portail {{instanceName}}</h1>
</div>

<div class="flex align-items-center">
	<button type="button" pButton class="p-button-rounded p-button-text" icon="pi pi-user" (click)="currentUtilisateurMenu.toggle($event)"></button>
	<p-menu #currentUtilisateurMenu [popup]="true" [model]="currentUtilisateurMenuItems" appendTo="body"></p-menu>
</div>
